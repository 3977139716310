import React from 'react'
import { NavLink } from 'react-router-dom'
import LanguageSelectorContainer from '../language/LanguageContainer'
import logo from './../../resources/logo-violet.svg'
import appleLogo from './../../resources/storeimg/apple-logo.svg'
import playLogo from './../../resources/storeimg/play-logo.svg'
import waLogo from './../../resources/wa-logo.svg'

const MainMenu = (props) => {

    let backDrop = () => {
        props.setVisible(false)
    }

    let menuItems = props.menu.menu.map(m =>
        <NavLink onClick={backDrop} className='tg-menu-item-mobile' to={m.url} activeStyle={{
            color: '#5856D6'
        }} >
            <h4>{m.text}</h4>
        </NavLink>
    )

    let apps = props.apps.apps.map( m =>
        <a href={m.url} target='_blank' rel='noopener noreffer'><img type='appLink' src={m.imgSm} alt={m.name} /></a>
    )

    return (
        <div>
            {
                props.visible
                    ?
                    <div className='tgMenu'>
                        <div className='tgMenuBackdrop' onClick={backDrop}>
                        </div>
                        <div className='tgMenuContainer'>
                            <div>
                                <div className='tgMenuHeader'>
                                    <img src={logo} alt='toGO' />
                                    <div>
                                        <LanguageSelectorContainer />
                                        <span class="material-icons" onClick={backDrop}>
                                            close
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    {menuItems}
                                </div>
                            </div>
                            <div>
                                <div className='tgMenuMobileApps'>
                                    {apps}
                                </div>
                                <div className='tgMenuMobileSupport'>
                                    <a type='apps' href='https://wa.me/79381587755'>
                                        <img src={waLogo} alt="WhatsApp" />
                                        <p>Написать в поддержку</p>
                                    </a>
                                    <p type='wa-number'>WhatsApp +7 (938) 158-77-55 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <span />
            }
        </div>
    )
}

export default MainMenu