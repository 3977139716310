import { connect } from 'react-redux';
import LanguageSelector from './LanguageSelector';
import { selectLanguage } from '../../redux/language';
import { showSelector } from '../../redux/language';

let mapStateTooProps = (state) => {
    return {
        lang: state.lang
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        selectLanguage: (newLanguage) => {
            let action = selectLanguage(newLanguage)
            dispatch(action)
        },
        showSelector: (visibleFlag) => {
            let action = showSelector(visibleFlag)
            dispatch(action)
        }
        
    }
}

const LanguageSelectorContainer = connect(mapStateTooProps, mapDispatchToProps)(LanguageSelector)

export default LanguageSelectorContainer