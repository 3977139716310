const CHANGE_LANGUAGE = 'CHANGE-LANGUAGE'
const SHOW_SELECTOR = 'SHOW-SELECTOR'

let initialState = {
    languages: [
        {
            languageCode: 'Ru',
            language: 'Русский',
            flag: 'pic here'
        }, {
            languageCode: 'En',
            language: 'English',
            flag: 'pic here'
        }
    ],

    currentLanguage: 'Ru',
    visibleSelector: false

}
let currentLanguage = localStorage.getItem('language')

let languageSet = () => {
    if (currentLanguage === '') {
        initialState.currentLanguage = 'Ru'
    } else if (currentLanguage === 'Ru') {
        initialState.currentLanguage = 'Ru'
    } else if (currentLanguage === 'En') {
        initialState.currentLanguage = 'En'
    }
}

let queryString = window.location.search
let urlParams = new URLSearchParams(queryString)
const urlLang = urlParams.get('lang')
let languageFromUrl = () => {
    if (urlLang === 'ru') {
        initialState.currentLanguage = 'Ru'
    } else if (urlLang === 'en') {
        initialState.currentLanguage = 'En'
    }
}

languageSet ()
languageFromUrl()

const languageReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHANGE_LANGUAGE:
            localStorage.setItem('language', action.newLanguage)
            return {
                ...state,
                currentLanguage: action.newLanguage,
            }
        case SHOW_SELECTOR:
            return {
                ...state,
                visibleSelector: action.visibleFlag
            }
        default: return state
    }
}

export const selectLanguage = (newLanguage) => ({ type: CHANGE_LANGUAGE, newLanguage })
export const showSelector = (visibleFlag) => ({ type: SHOW_SELECTOR, visibleFlag })

export default languageReducer