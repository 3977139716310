import telegram from './../resources/telegram.svg'
import wa from './../resources/wa-logo.svg'
import missionimg from './../resources/ok-hand.png'
import html from './../resources/tech/html.png'
import css from './../resources/tech/css.png'
import design from './../resources/tech/design.png'
import js from './../resources/tech/js.png'
import javascript from './../resources/tech/javascript.png'
import node from './../resources/tech/node.png'
import php from './../resources/tech/php.png'
import laravel from './../resources/tech/laravel.png'
import swift from './../resources/tech/swift.png'
import kotlin from './../resources/tech/kotlin.png'
import pyton from './../resources/tech/pyton.png'
import c from './../resources/tech/c.png'
import mail from './../resources/mail-icon.svg'




let initialState = {
    ru: {
        fair: {
            title: "toGO tech development",
            subtitle: "Разработка инфраструктур для шеринга",
        },
        contactUs: {
            title: "Заказать консультацию",
            links: [
                {
                    name: 'Telegram',
                    url: 'https://t.me/togokicksharing',
                    img: telegram,
                },
                {
                    name: 'WhatsApp',
                    url: 'https://api.whatsapp.com/send/?phone=79775388101',
                    img: wa,
                },
            ],
            reverceCall: {
                name: 'Обратная связь',
                img: mail,
            }
        },
        mission: {
            img: missionimg,
            title: 'Почему toGO tech',
            p1: 'Мы не пользуемся чужими идеями, а сами задаем тренды',
            p2: 'Практикуем системный подход в решении задач',
            p3: 'Доводим все проекты до успешного завершения',
            p4: 'Используем креативные идеи и инновационные подходы',
            p5: 'Мы всегда открыты к диалогу и готовы совершенствоваться',
            p6: 'Обеспечиваем полный спектр услуг по разработке пользовательских программ'
        },
        features: [
            {
                title: 'Современные подходы к разработке ПО',
                class: 'tg-dv-featureCard-one'
            }, {
                title: 'Актуальный стек технологий',
                class: 'tg-dv-featureCard-four'
            }, {
                title: 'Трендовый дизайн',
                class: 'tg-dv-featureCard-thirteen'
            }, {
                title: 'Уникальные решения',
                class: 'tg-dv-featureCard-fourteen'
            }, {
                title: 'Индивидуальный подход',
                class: 'tg-dv-featureCard-six'
            }, {
                title: 'Полный цикл работ «под ключ»',
                class: 'tg-dv-altCard-seven'
            }, {
                title: 'Интеграции с другими системами и ПО',
                class: 'tg-dv-featureCard-eleven'
            }, {
                title: 'Настройка под различные платформы',
                class: 'tg-dv-featureCard-eight'
            },
        ],
        tech: {
            title: 'Технологии',
            frontend: {
                title: 'Front end',
                items: [
                    {
                        img: html,
                        title: 'HTML5',
                    }, {
                        img: css,
                        title: 'CSS3',
                    }, {
                        img: design,
                        title: 'Design',
                    }, {
                        img: js,
                        title: 'Vue JavaScript',
                    }
                ],
            },
            backend: {
                title: 'Back end',
                items: [
                    {
                        img: javascript,
                        title: 'Javascript',
                    }, {
                        img: node,
                        title: 'Node.JS',
                    }, {
                        img: php,
                        title: 'PHP',
                    }, {
                        img: laravel,
                        title: 'Laravel',
                    }
                ],
            },
            mobile: 'Apps',
            ios: {
                img: swift,
                title: 'iOS',
                tech: 'Swift'
            },
            android: {
                img: kotlin,
                title: 'Android',
                tech: 'Kotlin'
            },
            embeddedTitle: 'Embedded systems',
            iot: {
                img: pyton,
                title: 'IoT',
                tech: 'Python'
            },
            embedded: {
                img: c,
                title: 'Embedded system',
                tech: 'C'
            },
        },
        whyusTitle: 'Преимущества toGO',
        whyus: [
            {
                title: 'Выполним проект любой сложности',
                class: 'tg-dv-altCard-twelve'
            }, {
                title: 'Нет скрытых доплат',
                class: 'tg-dv-altCard-two'
            }, {
                title: 'Несем полную отвественность',
                class: 'tg-dv-altCard-thirteen'
            }, {
                title: 'Система скидок за просрочки',
                class: 'tg-dv-altCard-six'
            }, {
                title: 'Выполним заказ в срок',
                class: 'tg-dv-featureCard-eight'
            }, {
                title: 'toGO — это штат квалифицированных инженеров разработки',
                class: 'tg-dv-featureCard-seven'
            }
        ],
    },
    en: {
        fair: {
            title: "toGO tech development",
            subtitle: "Разработка инфраструктур для шеринга",
        },
        contactUs: {
            title: "Заказать консультацию",
            links: [
                {
                    name: 'Telegram',
                    url: 'https://t.me/togokicksharing',
                    img: telegram,
                },
                {
                    name: 'WhatsApp',
                    url: 'https://api.whatsapp.com/send/?phone=79775388101',
                    img: wa,
                },
            ],
            reverceCall: {
                name: '',
                img: mail,
            }
        },
        mission: {
            img: missionimg,
            title: 'Почему toGO tech',
            p1: 'Мы не пользуемся чужими идеями, а сами задаем тренды',
            p2: 'Практикуем системный подход в решении задач',
            p3: 'Доводим все проекты до успешного завершения',
            p4: 'Используем креативные идеи и инновационные подходы',
            p5: 'Мы всегда открыты к диалогу и готовы совершенствоваться',
            p6: 'Обеспечиваем полный спектр услуг по разработке пользовательских программ'
        },
        features: [
            {
                title: 'Современные подходы к разработке ПО',
                class: 'tg-dv-featureCard-one'
            }, {
                title: 'Актуальный стек технологий',
                class: 'tg-dv-featureCard-four'
            }, {
                title: 'Трендовый дизайн',
                class: 'tg-dv-featureCard-thirteen'
            }, {
                title: 'Уникальные решения',
                class: 'tg-dv-featureCard-fourteen'
            }, {
                title: 'Индивидуальный подход',
                class: 'tg-dv-featureCard-six'
            }, {
                title: 'Полный цикл работ «под ключ»',
                class: 'tg-dv-altCard-seven'
            }, {
                title: 'Интеграции с другими системами и ПО',
                class: 'tg-dv-featureCard-eleven'
            }, {
                title: 'Настройка под различные платформы',
                class: 'tg-dv-featureCard-eight'
            },
        ],
        tech: {
            title: 'Технологии',
            frontend: {
                title: 'Front end',
                items: [
                    {
                        img: html,
                        title: 'HTML5',
                    }, {
                        img: css,
                        title: 'CSS3',
                    }, {
                        img: design,
                        title: 'Design',
                    }, {
                        img: js,
                        title: 'Vue JavaScript',
                    }
                ],
            },
            backend: {
                title: 'Back end',
                items: [
                    {
                        img: javascript,
                        title: 'Javascript',
                    }, {
                        img: node,
                        title: 'Node.JS',
                    }, {
                        img: php,
                        title: 'PHP',
                    }, {
                        img: laravel,
                        title: 'Laravel',
                    }
                ],
            },
            mobile: 'Apps',
            ios: {
                img: swift,
                title: 'iOS',
                tech: 'Swift'
            },
            android: {
                img: kotlin,
                title: 'Android',
                tech: 'Kotlin'
            },
            embeddedTitle: 'Embedded systems',
            iot: {
                img: pyton,
                title: 'IoT',
                tech: 'Python'
            },
            embedded: {
                img: c,
                title: 'Embedded system',
                tech: 'C'
            },
        },
        whyusTitle: 'Преимущества toGO',
        whyus: [
            {
                title: 'Выполним проект любой сложности',
                class: 'tg-dv-altCard-twelve'
            }, {
                title: 'Нет скрытых доплат',
                class: 'tg-dv-altCard-two'
            }, {
                title: 'Несем полную отвественность',
                class: 'tg-dv-altCard-thirteen'
            }, {
                title: 'Система скидок за просрочки',
                class: 'tg-dv-altCard-six'
            }, {
                title: 'Выполним заказ в срок',
                class: 'tg-dv-featureCard-eight'
            }, {
                title: 'toGO — это штат квалифицированных инженеров разработки',
                class: 'tg-dv-featureCard-seven'
            }
        ],
    }
}

const developmentReducer = (state = initialState, action) => {
    return state
}

export default developmentReducer