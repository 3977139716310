import React from 'react'
import ContactForm from './ContactForm'

const ContactPopover = (props) => {

    let closePopup = () => {
        props.setVisiblePopover(false)
        props.setVisibleForm(true)
    }
    let openPopup = () => {
        props.setVisiblePopover(true)
    }

    return (
        <div>
            {
                props.bools.visiblePopover
                    ? <div>
                        <div className='contactPopup'>
                            <div className='popCoverImg'></div>
                            <span class="material-icons" onClick={closePopup}>
                                close
                            </span>
                            {
                                props.bools.visibleForm
                                    ? <ContactForm state={props.state} bools={props.bools} setVisibleForm={props.setVisibleForm} sendError={props.sendError} />
                                    : <div className='successMessage'>
                                        <span class="material-icons">thumb_up</span>
                                        <h3>{props.state.success.title}</h3>
                                        <p>{props.state.success.subtitle}</p>
                                        <button className='greenBtn' onClick={closePopup}>{props.state.success.btnText}</button>
                                    </div>
                            }
                        </div>
                        <div className='contactDrop' onClick={closePopup}>
                        </div>
                    </div>
                    : <span></span>
            }
        </div>
    )
}

export default ContactPopover