import placeholder from './../resources/client/graf-banner.png'

import one from './../resources/social/instagram/1.jpg'
import two from './../resources/social/instagram/2.jpg'
import three from './../resources/social/instagram/3.jpg'
import four from './../resources/social/instagram/4.jpg'
import five from './../resources/social/instagram/5.jpg'
import six from './../resources/social/instagram/6.jpg'
import seven from './../resources/social/instagram/7.jpg'
import eight from './../resources/social/instagram/8.jpg'

let initialState = {
    arr: [
        {
            img: one,
            title: 'В Роскачестве назвали главные уязвимости приложений для аренды самокатов',
            subtitle: 'Специалисты Роскачества изучили и проанализировали политику конфиденциальности 68 приложений проката для IOS и Android',
            source: {
                logo: 'https://kuzbass.aif.ru/favicon.ico',
                name: 'kuzbass.aif.ru',
                url: 'https://kuzbass.aif.ru/hitech/v_roskachestve_nazvali_glavnye_uyazvimosti_prilozheniy_dlya_arendy_samokatov',
            },
        },{
            img: two,
            title: 'На Юго-Западе столицы сервис проката самокатов открылся в районах Гагаринский и Ясенево',
            subtitle: 'Как сообщает Агентство городских новостей «Москва» со ссылкой на пресс-службу сервиса, услуга проката самокатов предусматривает бесстанционную модель работы',
            source: {
                logo: 'https://yasenevo.mos.ru/upload/resize_cache/fb/logos_yasenevo.png',
                name: 'mos.ru',
                url: 'https://yasenevo.mos.ru/presscenter/news/detail/8293407.html',
            },
        },{
            img: three,
            title: 'Более 500 «медленных зон» для электросамокатов может появиться в Москве в июле',
            subtitle: 'Об этом сообщили в пресс-службах сервисов кикшеринга toGO и Urent',
            source: {
                logo: 'https://www.m24.ru/img/fav/favicon-32x32.png',
                name: 'm24.ru',
                url: 'https://www.m24.ru/news/gorod/11062021/169354',
            },
        },{
            img: four,
            title: 'Воронежцам предложили поучаствовать в квесте на электросамокатах',
            subtitle: 'Туристско-информационный центр Воронежа" и компания "Кикшеринг toGO" приглашают жителей столицы Черноземья принять участие во втором городском квесте на электросамокатах',
            source: {
                logo: 'https://bloknot-voronezh.ru/bitrix/templates/bloknot/favicon.ico',
                name: 'bloknot-voronezh.ru',
                url: 'https://bloknot-voronezh.ru/news/voronezhtsam-predlozhili-pouchastvovat-v-kveste-na-1131655',
            },
        },{
            img: five,
            title: 'Оператор проката электросамокатов toGo начнет работу в Москве с 14 августа',
            subtitle: 'Оператор проката электросамокатов ToGo начнет работу в Москве с 14 августа 2019 г., сообщили Агентству городских новостей «Москва» в пресс-службе сервиса',
            source: {
                logo: 'https://www.mskagency.ru/favicon.ico',
                name: 'mskagency.ru',
                url: 'https://www.mskagency.ru/materials/2917067',
            },
        },
        {
            img: six,
            title: 'Новый оператор проката самокатов начнет работать в Москве в ближайшее время',
            subtitle: 'Четвертый по счету оператор проката самокатов toGo планирует начать работу в Москве в ближайшее время, сообщили ТАСС в столичном департаменте транспорта',
            source: {
                logo: 'https://tass.ru/img/blocks/common/apple-touch-icon.png',
                name: 'tass.ru',
                url: 'https://tass.ru/moskva/6730662',
            },
        },{
            img: seven,
            title: '«Самокат по цене бургера». Как работает первый шеринг электросамокатов в Перми',
            subtitle: 'Сегодня на  пермских улицах нередко можно увидеть людей на зелено-фиолетовых самокатах. Так работает кикшеринг  -  поминутная аренда электросамокатов через мобильное приложение.',
            source: {
                logo: 'https://www.chitaitext.ru/favicon.ico',
                name: 'chitaitext.ru',
                url: 'https://www.chitaitext.ru/novosti/samokat-po-tsene-burgera-kak-rabotaet-pervyy-shering-elektrosamokatov-v-permi/',
            },
        },{
            img: eight,
            title: 'Сервис по прокату самокатов toGO планирует запуск в четырех городах Подмосковья в мае-июне',
            subtitle: 'Сервис аренды электросамокатов toGO планирует расширить прокат на четыре города Подмосковья в мае-июне 2021 года, сообщили Агентству городских новостей «Москва» в пресс-службе сервиса.',
            source: {
                logo: 'https://www.mskagency.ru/favicon.ico',
                name: 'mskagency.ru',
                url: 'https://www.mskagency.ru/materials/3103211',
            },
        },
        
        // {
        //     img: 'https://static.ngs.ru/news/2020/99/preview/8af9753990d76ee38395a06db82ffb1521492dbe6_1200_800_c.jpg.webp-portal',
        //     title: '«Коронавирус нам только помог, а погода дает работать»: совладелец кикшеринга — о бизнесе на самокатах',
        //     subtitle: 'В Екатеринбурге появился новый вид транспорта',
        //     source: {
        //         logo: 'https://static.ngs.ru/jtnews/dist/static/favicons/favicon-e1-32.ico',
        //         name: 'e1.ru',
        //         url: 'https://www.e1.ru/text/business/2020/05/21/69270400/',
        //     },
        // },{
        //     img: 'https://cdn.mesto.ru/uploads/post/news/wide_picture/12046/%D0%BA%D0%B8%D0%BA%D1%88%D0%B5%D1%80%D0%B8%D0%BD%D0%B3.jpg',
        //     title: 'ГК «А101» и toGO запустили кикшеринговый сервис в Коммунарке',
        //     subtitle: 'Через специальное приложение пользователь может взять в краткосрочную аренду электросамокат и добраться на нем до метро «Коммунарка»',
        //     source: {
        //         logo: 'https://www.mesto.ru/favicon.ico?rev-b',
        //         name: 'mesto.ru',
        //         url: 'https://www.mesto.ru/news/gk-a101-i-togo-zapustili-kiksheringovyy-servis-v-kommunarke.html',
        //     },
        // },{
        //     img: 'https://s0.rbk.ru/v6_top_pics/resized/590xH/media/img/0/15/755686419322150.jpg',
        //     title: 'Застройщик запустил в Новой Москве кикшеринг до метро',
        //     subtitle: 'Теперь жители Коммунарки смогут добраться до одноименной станции столичной подземки на электросамокатах за 15 минут',
        //     source: {
        //         logo: 'https://s.rbk.ru/v5_realty_static/common/common-10.8.27/images/favicon.png',
        //         name: 'rbc.ru',
        //         url: 'https://realty.rbc.ru/news/5d7f92ee9a79475dc1902abc',
        //     },
        // },{
        //     img: 'https://to-go.life/static/media/graf-banner.b2991c10.png',
        //     title: 'Почему франшизы выбирают Юг России для развития своего бизнеса',
        //     subtitle: 'Краснодарский край и соседние регионы уже несколько лет лидируют в списках самых активных субъектов Федерации по отношению к франчайзингу.',
        //     source: {
        //         logo: 'https://franshiza.ru/img/favicon/apple-icon-57x57.png',
        //         name: 'franshiza.ru',
        //         url: 'https://franshiza.ru/article/read/pochemu_franshizy_vybirayut_yug_rossii_dlya_razvitiya_svoego_biznesa/',
        //     },
        // },{
        //     img: 'https://to-go.life/static/media/graf-banner.b2991c10.png',
        //     title: 'Сервисы кикшеринга toGO, Urent и Whoosh готовы к новым правилам работы в Москве',
        //     subtitle: 'Это не потребует дополнительных затрат и не повлияет на стоимость услуг. Об этом Агентству городских новостей «Москва» сообщили в пресс-службах компаний',
        //     source: {
        //         logo: 'https://www.mskagency.ru/favicon.ico',
        //         name: 'mskagency.ru',
        //         url: 'https://www.mskagency.ru/materials/3121507',
        //     },
        // },{
        //     img: 'https://moslenta.ru/imgs/2021/06/11/11/4713264/f50ac9681b95cbfbe297736cd4f7b360d7dbb57f.jpg',
        //     title: 'В Москве создадут полтысячи «медленных зон» для электросамокатов',
        //     subtitle: 'Об этом сообщает Агентство городских новостей «Москва» со ссылкой на пресс-службу сервисов кикшеринга toGO и Urent.',
        //     source: {
        //         logo: 'https://moslenta.ru/favicon.ico',
        //         name: 'moslenta.ru',
        //         url: 'https://moslenta.ru/news/v-moskve-sozdadut-poltysyachi-medlennykh-zon-dlya-elektrosamokatov-11-06-2021.htm',
        //     },
        // },{
        //     img: 'https://storage.yandexcloud.net/incrussia-prod/wp-content/uploads/2021/07/Cover-Samokatnyi-Bum.jpg',
        //     title: '«Оттолкнись и катись»: далеко ли уедут электросамокаты в России.',
        //     subtitle: 'Исследование рынка кикшеринга',
        //     source: {
        //         logo: 'https://incrussia.ru/wp-content/themes/inc/favicon/next/favicon-32x32',
        //         name: 'incrussia.ru',
        //         url: 'https://incrussia.ru/understand/kicksharing-2/',
        //     },
        // },

    ]
}

const PressReducer = (state = initialState, action) => {
    return state
}

export default PressReducer