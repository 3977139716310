import { connect } from 'react-redux'
import ContactPopover from './ContactPopover'
import {setVisibleForm} from '../../redux/contactForm'
import {setVisiblePopover} from '../../redux/contactForm'
import {sendError} from '../../redux/contactForm'

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            state: state.contactForm.ru,
            bools: state.contactForm.bools
        }
    } else { 
        return {
            state: state.contactForm.en,
            bools: state.contactForm.bools
        }
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisibleForm: (visible) => {
            dispatch(setVisibleForm(visible))
        },
        setVisiblePopover: (visible) => {
            dispatch(setVisiblePopover(visible))
        },
        sendError: (visible) => {
            dispatch(sendError(visible))
        }
    }
}

const ContactPopoverContainer = connect(mapStateTooProps, mapDispatchToProps)(ContactPopover)

export default ContactPopoverContainer