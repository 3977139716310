import React from 'react'
import logoViolet from './../../resources/client/logo-violet.svg'

import { NavLink } from 'react-router-dom'
import LanguageSelectorContainer from '../language/LanguageContainer'

const Header = (props) => {
    let backDrop = () => {
        props.setVisible(true)
    }

    let social = props.social.items.map(m =>
        <a href={m.link} target='_blank' rel="noopener noreferrer"><img src={m.icon} alt={m.alt} /></a>
    )

    let apps = props.apps.apps.map(m => 
        <a href={m.url} target='_blank' rel="noopener noreferrer"><img type='appLink' src={m.imgSm} alt={m.name} /></a>
        )

    let mainMenu = props.menu.menu.map(m =>
        <NavLink className='tg-menu-item' to={m.url} activeStyle={{
            borderBottom: "4px solid #5856D6",
            color: '#5856D6'
        }}>
            <p>{m.text}</p>
        </NavLink>
    )

    return (
        <div>
            <div className='headerContainer'>
                <div className='tg-header-social'>
                    <NavLink to='/' type='logo'>
                        <img type='logo' src={logoViolet} alt='toGO' />
                    </NavLink>
                    <span>
                        {social}
                    </span>
                </div>
                <div className='tg-header-apps'>
                    <div className='tg-menu-items'>
                        {mainMenu}
                    </div>
                    <a type='apps' href={props.support.url}>
                        <img src={props.support.waLogo} alt="WhatsApp" />
                        <p>{props.support.p}</p>
                    </a>
                    {apps}

                    <LanguageSelectorContainer />
                </div>
            </div>
            <div className='headerContainerMobile'>
                <div>
                    <NavLink to='/' type='logo'>
                        <img type='logo' src={logoViolet} alt='toGO' />
                    </NavLink>
                    {apps}
                </div>
                <i onClick={backDrop} className="material-icons">menu</i>
            </div>
        </div>
    )
}

export default Header