import React from 'react'
import { animateScroll as scroll } from "react-scroll";
import HeaderContainer from '../other/HeaderContainer';
import Joining from './Joining';

const JoiningPage = (props) => {
    scroll.scrollToTop()

    return (
        <div>
            <HeaderContainer />
            <div className='docPage'>
                <div className='docContainer'>
                    <h1>Договор присоединения</h1>
                    <Joining />
                </div>
            </div>
        </div>
    )
}

export default JoiningPage