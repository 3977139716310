import { combineReducers, createStore } from "redux";
import mainReducer from "./main";
import footerReducer from "./footer";
import kicksharingReducer from "./kicksharing";
import ownBrandReducer from "./ownBrand";
import stationsReducer from "./stations";
import equipmentReducer from "./equipment";
import mainMenuReducer from "./mainMenu";
import faqReducer from "./faq";
import appsReducer from "./apps";
import contactFormReducer from "./contactForm";
import ClientPageReducer from "./clientPage";
import PressReducer from "./press";
import CertificatesReducer from "./certificates";
import languageReducer from "./language";
import supportReducer from "./support";
import businessReducer from "./business";
import franchaiseReducer from "./franchaise";
import developmentReducer from "./development";
import powerBankReducer from "./franchaisePowerbank";
import AccreditationReducer from "./accreditation";


let reducers = combineReducers ({
    client: ClientPageReducer,
    accreditation: AccreditationReducer,
    business: businessReducer,
    kicksharing: kicksharingReducer,
    ownbrand: ownBrandReducer,
    stations: stationsReducer,
    equipment: equipmentReducer,
    footer: footerReducer,
    mainMenu: mainMenuReducer,
    faq: faqReducer,
    apps: appsReducer,
    contactForm: contactFormReducer,
    press: PressReducer,
    certificates: CertificatesReducer,
    lang: languageReducer,
    support: supportReducer,
    footer: footerReducer,
    franchaise: franchaiseReducer,
    development: developmentReducer,
    powerbank: powerBankReducer
})

let store = createStore(reducers)

export default store