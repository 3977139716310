import React from 'react'
import { animateScroll as scroll } from "react-scroll";

const AppsPage = (props) => {
    scroll.scrollToTop()
    let apps = props.state.apps.map(m => 
        <a href={m.url} target='_blank' rel="noopener noreferrer">
            <img src={m.img} alt={m.name} />
        </a>
        )
    return (
        <div className='apps'>
            <div className='appsBg'></div>
            <div className='appsTop'>
                <img src={props.state.appIcon} alt='toGO sharing' />
                <h2>{props.state.subTitle}</h2>
            </div>
            <div className='appsBottom'>
                {apps}
                <h3>{props.state.footerNote}</h3>
            </div>
        </div>
    )
}

export default AppsPage