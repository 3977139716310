import { connect } from 'react-redux';
import AppsPage from './AppsPage';

let mapStateTooProps = (state) => {
        return {
            state: state.apps        
        }
    }
const AppsPageContainer = connect(mapStateTooProps)(AppsPage)

export default AppsPageContainer