import rospatentimg from './../resources/certificates/rospatent.png'
import accreditationimg from './../resources/certificates/min-digital.png'

import rospatent from './../resources/certificates/evidence-rospatent.pdf'
import accreditation from './../resources/certificates/accreditation.pdf'

let initialState = {
    arr: [
        {
            img: accreditationimg,
            title: 'Выписка из реестра аккредитованных организаций в области IT',
            certificate: accreditation,
        },{
            img: rospatentimg,
            title: 'Свидетельство о государственной регистрации программного обеспечения',
            certificate: rospatent,
        }
    ]
}

const AccreditationReducer = (state = initialState, action) => {
    return state
}

export default AccreditationReducer