import appIcon from './../resources/storeimg/togo-appicon-2022.webp'
import appstore from './../resources/storeimg/AppStore.svg'
import appstoreSm from './../resources/storeimg/apple-logo.svg'

import playMarket from './../resources/storeimg/PlayMarket.svg'
import playMarketSm from './../resources/storeimg/play-logo.svg'

import appGallery from './../resources/storeimg/AppGallery.png'
import appGallerySm from './../resources/storeimg/gallery-logo.svg'



let initialState = {
    appIcon: appIcon,
    title: 'toGO',
    subTitle: 'Загрузите приложение toGO на свой смартфон',
    footerNote: '— Cокращаем расстояния',
    apps: [
        {
            name: 'AppStore',
            img: appstore,
            imgSm: appstoreSm,
            url: 'https://apps.apple.com/us/app/togo-2-0/id6449598196',
        },{
            name: 'PlayMarket',
            img: playMarket,
            imgSm: playMarketSm,
            url: 'https://play.google.com/store/apps/details?id=cloud.togotech.togo.togo',
        }
        //,{
        //     name: 'AppGallery',
        //     img: appGallery,
        //     imgSm: appGallerySm,
        //     url: 'https://appgallery.huawei.com/app/C104838901',
        // }
    ],
}

const appsReducer = (state = initialState, action) => {
    return state
}

export default appsReducer