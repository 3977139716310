import { connect } from 'react-redux';
import {setVisiblePopover} from '../../redux/contactForm'
import {setVisibleForm} from '../../redux/contactForm'
import {sendError} from '../../redux/contactForm'
import Franchaise from './Franchaise';

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            state: state.franchaise.ru,
            apps: state.apps,
            form: state.contactForm.ru,
            bools: state.contactForm.bools
        }
    } else { 
        return {
            state: state.franchaise.en,
            apps: state.apps,
            form: state.contactForm.en,
            bools: state.contactForm.bools
        }
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisiblePopover: (visible) => {
            dispatch(setVisiblePopover(visible))
        },
        setVisiblePopover: (visible) => {
            dispatch(setVisiblePopover(visible))
        },
        sendError: (visible) => {
            dispatch(sendError(visible))
        }
}}

const FranchaiseContainer = connect(mapStateTooProps, mapDispatchToProps)(Franchaise)

export default FranchaiseContainer