import { connect } from 'react-redux';
import Faq from './Faq';

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            state: state.faq.ru
        }
    } else { 
        return {
            state: state.faq.en
        }
    }
}

const FaqContainer = connect(mapStateTooProps)(Faq)

export default FaqContainer