import integrations from './../resources/ownBrand/opportunities/integrations.svg'
import widgets from './../resources/ownBrand/opportunities/widgets.svg'
import emoji from './../resources/ownBrand/emoji.png'

import one from './../resources/other/nums/1.svg'
import two from './../resources/other/nums/2.svg'
import three from './../resources/other/nums/3.svg'

import kicksharing from './../resources/ownBrand/kicksharing.png'

let initialState = {
    cover:{
        className: 'ownBrandCover',
        title: 'Ваш бренд ',
        span: 'кикшеринга'
    },
    solution: {
        title: 'Мы предоставляем Вам полный консалтинг:',
        items: [
            {
                img: one,
                alt: 1,
                text: 'Техническое решение для самоката Ninebot Max или Ninebot Max Pro, в который входит наш собственный IOT- модуль и элетромеханический замок'

            },
            {
                img: two,
                alt: 2,
                text: 'Кастомизированное под ваш фирменный стиль мобильное приложение. Размещение в AppStore и PlayMarket под вашим брендом'

            },
            {
                img: three,
                alt: 3,
                text: 'Вам останется только выбрать банк для эквайринга или воспользоваться услугами нашего банка-партнера, а также получить DNS код в App Store'
            },
        ]
    },
    conditions: {
        img: emoji, 
        alt: 'finance smile',
        title: 'Финансовые условия системы',
        subtitle: 'Решение toGO обеспечивает круглосуточный мониторинг, эквайринг и техническую поддержку',
        list: [
            {
                text: '1 вариант: Ежемесячные платежи составят 9% от выручки'
            },
            {
                text: '2 вариант: Ежемесячные платежи составят €7 за каждый активный самокат'
            },
            {
                text: 'Все денежные средства от проката самокатов -  поступают на Расчетный счёт Вашего юридического лица' 
            },
        ],
    },
    specialOffer:{
        title: 'Специальные возможности для партнеров toGO',
        subtitle: 'Простой запуск и постоянная поддержка партнеров toGO снимет с ваших плеч заботу о большинстве трудностей при запуске кикшеринга',
        items: [
            {
                img: integrations,
                alt: 'integrations',
                title: 'Интеграции с другими брендами',
                text: 'Например toGO, SanWheels, Osamokat, toGoGeo и Bumerang'
            },
            // {
            //     img: widgets,
            //     alt: 'Sharing with one platform',
            //     title: 'Шеринг на одной платформе',
            //     text: 'Запуск каршеринга, байкшеринга, скутершеринга на одной платформе и одном приложении'
            // },
        ]
    },   
    kicksharing: {
        img: kicksharing,
        alt: 'spray-art',
        title: 'Легкий запуск в Вашем городе',
        subtitle: 'Нет взносов и переплаты посредникам!  . Ваши инвестиции только в закупку Самокатов и их оборудование!',
        nav: false,
        button: 'Свяжитесь с нами',
        // url: '/kicksharing',
    },
}

const ownBrandReducer = (state = initialState, action) => {
    return state
}

export default ownBrandReducer