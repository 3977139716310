import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import { animateScroll as scroll } from "react-scroll";
import ContactPopoverContainer from '../ContactForm/ContactPopoverContainer';



const Powerbanks = (props) => {
    scroll.scrollToTop()

    let openPopup = () => {
        props.setVisiblePopover(true)
    }
    let contacts = props.state.contactUs.links.map(m =>
        <a href={m.url} target='_blank' rel="noopener noreferrer"><img src={m.img} alt={m.name} /><h4>{m.name}</h4></a>
    )

    let analyze = props.state.analyze.items.map(m =>
        <div className='tg-pb-itemcard-num'>
            <h2>{m.quontity}</h2>
            <h4>{m.p}</h4>
        </div>
    )

    let statistic = props.state.statistic.items.map(m =>
        <div className='tg-pb-itemcard-num'>
            <h2>{m.quontity}</h2>
            <h4>{m.p}</h4>
        </div>
    )

    let finance = props.state.finance.items.map(m =>
        <div className='tg-pb-itemcard-icon'>
            <span class="material-icons">{m.icon}</span>
            <h2>{m.title}</h2>
            {
                m.ul.map(n =>
                    <p>{n}</p>)
            }
        </div>
    )

    let solution = props.state.solution.items.map(m =>
        <div className='tg-pb-itemcard-icon-alt'>
            <span class="material-icons">{m.icon}</span>
            <h2>{m.title}</h2>
            {
                m.ul.map(n =>
                    <p>{n}</p>)
            }
        </div>
    )

    let features = props.state.features.items.map(m =>
        <div className='tg-pb-itemcard-icon-sm'>
            <span class="material-icons">{m.icon}</span>
            <h2>{m.title}</h2>
            <h4>{m.p}</h4>
        </div>
    )

    let mobileApp = props.state.mobileApp.items.map(m =>
        <div className='tg-pb-itemcard-num-sm'>
            <h2>{m.num}</h2>
            <h4>{m.p}</h4>
        </div>
    )


    return (
        <div>
            <div className='tg-pb-fair'>
                <div className='tg-pb-container'>
                    <h1>{props.state.fair.title}</h1>
                    <h2>{props.state.fair.subtitle}</h2>
                    <div className='tg-pb-fair-contact'>
                        <h3>{props.state.contactUs.title}</h3>
                        <div>
                            {contacts}
                            <a href='#' onClick={openPopup}><img src={props.state.contactUs.reverceCall.img} alt={props.state.contactUs.reverceCall.name} /><h4>{props.state.contactUs.reverceCall.name}</h4></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tg-pb-container'>
                <div className='tg-pb-add-header'>
                    <h2>{props.state.partnersProgram.title}</h2>
                    <div></div>
                </div>
                <div className='tg-pb-img-block'>
                    <div>
                        <div>
                            <h4>{props.state.partnersProgram.p1}</h4>
                            <h4>{props.state.partnersProgram.p2}</h4>
                        </div>
                    </div>
                    <img src={props.state.partnersProgram.img} alt="Hello" />
                </div>
            </div>

            <div className='tg-pb-container'>
                <h2 className='tg-pb-header'>{props.state.analyze.title}</h2>
                <div className='tg-pb-3-col'>
                    {analyze}
                </div>
                <p className='tg-pb-helper'>{props.state.analyze.subtitle}</p>
            </div>

            <div className='tg-pb-container'>
                <h2 className='tg-pb-header'>{props.state.statistic.title}</h2>
                <p className='tg-pb-subtitle'>{props.state.statistic.p1}</p>
                <p className='tg-pb-subtitle'>{props.state.statistic.p2}</p>
                <div className='tg-pb-3-col'>
                    {statistic}
                </div>
            </div>

            <div className='tg-pb-container'>
                <h2 className='tg-pb-header'>{props.state.finance.title}</h2>
                <div className='tg-pb-3-col'>
                    {finance}
                </div>
            </div>

            <div className='tg-pb-fair-violet'>
                <div className='tg-pb-container'>
                    <h2 className='tg-pb-header-white'>{props.state.solution.title}</h2>
                    <div className='tg-pb-2-col'>
                        {solution}
                    </div>
                </div>
            </div>

            <div className='tg-pb-fair-green'>
                <div className='tg-pb-container'>
                    <h2 className='tg-pb-header-white'>{props.state.features.title}</h2>
                    <div className='tg-pb-3-col'>
                        {features}
                    </div>
                </div>
            </div>

            <div className='tg-pb-container'>
                <h2 className='tg-pb-header'>{props.state.howitworks.title}</h2>
                <div className='tg-pb-img-block'>
                    <div>
                        <div>
                            <h4>{props.state.howitworks.p1}</h4>
                            <h4>{props.state.howitworks.p2}</h4>
                            <h4>{props.state.howitworks.p3}</h4>
                        </div>
                    </div>
                    <img src={props.state.howitworks.img} alt="Hello" />
                </div>
            </div>

            <div className='tg-pb-container'>
                <h2 className='tg-pb-header'>{props.state.powerbank.title}</h2>
                <div className='tg-pb-img-block'>
                    <div>
                        <div>
                            <h4>{props.state.powerbank.p1}</h4>
                            <h4>{props.state.powerbank.p2}</h4>
                            <h4>{props.state.powerbank.p3}</h4>
                        </div>
                    </div>
                    <img src={props.state.powerbank.img} alt="Hello" />
                </div>
            </div>

            <div className='tg-pb-container'>
                <h2 className='tg-pb-header'>{props.state.mobileApp.title}</h2>
                <div className='tg-pb-img-block-alt'>
                    <div className='tg-pb-2-col'>
                        {mobileApp}
                        <div className='tg-pb-infoblock'>
                            <h2>{props.state.infosystem.title}</h2>
                            <p>{props.state.infosystem.subtitle}</p>
                        </div>
                    </div>
                    <img src={props.state.mobileApp.img} alt="Hello" />
                </div>
            </div>
            <div className='tg-pb-fair-gray'>
                <div className='tg-pb-container-form'>
                    <div className='tg-pb-catch'>
                        <h2>{props.state.contactUs.title}</h2>
                        <div>
                            {contacts}
                            <a href='#' onClick={openPopup}><img src={props.state.contactUs.reverceCall.img} alt={props.state.contactUs.reverceCall.name} /><h4>{props.state.contactUs.reverceCall.name}</h4></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Powerbanks