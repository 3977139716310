import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainMenuContainer from './components/mainMenu/MainMenuContainer';
import FaqContainerPage from './components/faq/FaqContainerPage';
import JoiningPage from './components/docs/JoiningPage';
import PrivacyPage from './components/docs/PrivacyPage';
import AppsPageContainer from './components/upload/AppsPageContainer';
import ContactPopoverContainer from './components/ContactForm/ContactPopoverContainer';
import ClientPageContainer from './components/Client/ClientPageContainer';
import HeaderContainer from './components/other/HeaderContainer';
import PressContainer from './components/press/PressContainer';
import CertificatesContainer from './components/certificates/CertificatesContainer';
import BusinessContainer from './components/main/BusinessContainer';
import FooterContainer from './components/other/FooterContainer';
import FranchaiseContainer from './components/main/FranchaiseContainer';
import DevelopmentContainer from './components/main/DevelopmentContainer';
import PowerbanksContainer from './components/main/PowerbanksContainer';
import AccreditationContainer from './components/accreditation/AccreditationContainer';

function App() {
  return (
    <div>
      <HeaderContainer />
      <MainMenuContainer />
      <ContactPopoverContainer />
      <Switch>
        <Route path='/client' render={() => <ClientPageContainer />} />
        <Route exact path='/franchise' render={() => <FranchaiseContainer />} />
        <Route exact path='/development' render={() => < DevelopmentContainer/>} />
        <Route exact path='/charge' render={() => < PowerbanksContainer/>} />

        <Route path='/certificates' render={() => <CertificatesContainer />} />
        <Route path='/accreditation' render={() => <AccreditationContainer />} />

        <Route path='/quick-faq' render={() => <FaqContainerPage />} />
        <Route path='/joining-togo' render={() => <JoiningPage />} />
        <Route path='/privacy-policy' render={() => <PrivacyPage />} />
        <Route path='/download' render={() => <AppsPageContainer />} />
        <Route path='/press' render={() => <PressContainer />} />
        <Route render={() => <ClientPageContainer />} />
      </Switch>
      <FooterContainer/>
    </div>
  )
}

export default App;
