
const SET_VISIBLE_FORM = 'SET-VISIBLE-FORM'
const SET_VISIBLE_POPOVER = 'SET-VISIBLE-POPOVER'
const SET_VISIBLE_ERROR = 'SET-VISIBLE-ERROR'

let initialState = {
    ru: {
        title: 'Свяжитесь с нами',
        fields: {
            name: {
                label: 'Имя',
                placeholder: 'Константин'
            },
            email: {
                label: 'Email',
                placeholder: 'yourmail@domain.com'
            },
            phone: {
                label: 'Номер телефона',
                placeholder: '+7 900 111 22 33'
            },
            message: {
                label: 'Сообщение',
                placeholder: 'Ваше сообщение'
            },
            submit: 'Отправить',
            errorMessage: 'Что-то пошло не так – ваше сообщение не было отправлено'
        },
        success: {
            title: 'Сообщение отправлено!',
            subtitle: 'Мы свяжемся с вами в ближайшее время',
            btnText: 'Продолжить'
        }
    },
    en: {
        title: 'Contact us',
        fields: {
            name: {
                label: 'Name',
                placeholder: 'John Smith'
            },
            email: {
                label: 'Email',
                placeholder: 'yourmail@domain.com'
            },
            phone: {
                label: 'Phone number',
                placeholder: '+7 900 111 22 33'
            },
            message: {
                label: 'Message',
                placeholder: 'Your message for us'
            },
            submit: 'Send',
            errorMessage: 'Something went wrong - your message was not sent'
        },
        success: {
            title: 'Your message sent!',
            subtitle: 'We will contact you shortly',
            btnText: 'Continue'
        }
    },
    bools: {
        visibleForm: true,
        visiblePopover: false,
        sendError: false,
    }
}

const contactFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VISIBLE_FORM:
            return {
                ...state,
                bools: {...state.bools, visibleForm: action.visible}
            }
        case SET_VISIBLE_POPOVER:
            return {
                ...state,
                bools: {...state.bools, visiblePopover: action.visible}
            }
        case SET_VISIBLE_ERROR:
            return {
                ...state,
                bools: {...state.bools, sendError: action.visible}
            }
        default:
            return state
    }
}

export const setVisibleForm = (visible) => ({
    type: SET_VISIBLE_FORM,
    visible: visible
})

export const setVisiblePopover = (visible) => ({
    type: SET_VISIBLE_POPOVER,
    visible: visible
})

export const sendError = (visible) => ({
    type: SET_VISIBLE_ERROR,
    visible: visible
})

export default contactFormReducer