import mainBanner from './../resources/main-banner.png'
import kicksharing from './../resources/sections/kicksharing.png'
import paintBrush from './../resources/sections/paint-brush.png'
import flashLight from './../resources/sections/flash-light.png'
import monitor from './../resources/sections/monitor.png'
import greenWave from './../resources/green-wave.svg'

import scooter from './../resources/kicksharing/scooter-item.png'


let initialState = {
    bgImage: mainBanner,
    tagline: 'Сокращаем расстояния по всему миру!',
    subline: 'Ведущий независимый международный кикшеринг. Основан в 2018 году',
    sections: [
        // {
        //     id: 'section-1',
        //     title: 'Лёгкий запуск в Вашем городе',
        //     text: 'Готовый бизнес за 1 020 000 руб.',
        //     img: scooter,
        //     class: 'darkVioletCard',
        //     menuClass: 'darkVioletCardMenu',
        //     link: '/business/kicksharing'
        // },
        {
            id: 'section-4',
            title: 'Оборудование для самокатов',
            text: 'Электросамокаты, GSM трекеры и прочее оборудование',
            img: monitor,
            class: 'whiteCard',
            menuClass: 'whiteCardMenu',
            link: '/business/equipment'
        },
        {
            id: 'section-3',
            title: 'Зарядные станции для самокатов',
            text: 'Cтационарные зарядные станции для кикшеринга от ведущего мирового производителя за 280 000 руб.',
            img: flashLight,
            class: 'violetCard',
            menuClass: 'violetCardMenu',
            link: '/business/stations'
        },
    ],
    mainFeatures: {
        divider: greenWave,

        citiesText: 'городов запустили сервис toGO',
        cities: [
            {
                title: 'Москва',
            },
            {
                title: 'Краснознаменск',
            },
            {
                title: 'Геленджик',
            },
            {
                title: 'Ростов-на-Дону',
            },
            {
                title: 'Дербент',
            },
            {
                title: 'Тбилиси',
            },
            {
                title: 'Батуми',
            },
            {
                title: 'Бишкек',
            },
            {
                title: 'Калининград',
            },
        ],

        projectTitle: 'Более',
        projectNumber: '15',
        projetText: 'сервисов кикшеринга запущено командой toGO',

        // consultTitle: 'Брендов',
        // consultNumber: '10',
        // consultText: 'Получили консалтинг по прокату самокатов',

    },
    partnersTitle: 'Наши партнеры',
    contactUs: {
        title: 'Легкий запуск бизнеса по прокату самокатов 🚀',
        subtitle: 'Мы гарантируем Вам - лучшее  финансовое  и техническое  решение для бизнеса',
        nav: false,
        button: '🛴 Открытие Кикшеринга в вашем городе',
        style: 'ownBrand'
    },

}

const mainReducer = (state = initialState, action) => {
    return state
}

export default mainReducer