import React from 'react'
import { animateScroll as scroll } from "react-scroll";
import FaqGroup from './FaqGroup';

import { Accordion } from 'react-accessible-accordion';

const Faq = (props) => {
    scroll.scrollToTop()

    let faqGroups = props.state.faq.map(m =>
        <FaqGroup title={m.title} q={m.q} />
    )

    return (
        <div>
            <div className='faqContainer'>
                <Accordion allowMultipleExpanded='true' allowZeroExpanded='true'>
                    {faqGroups}
                </Accordion>
            </div>
        </div>
    )
}

export default Faq