import React from 'react'
import { animateScroll as scroll } from "react-scroll";
import HeaderContainer from '../other/HeaderContainer';
import Privacy from './Privacy';

const PrivacyPage = (props) => {
    scroll.scrollToTop()

    return (
        <div>
            <HeaderContainer />
            <div className='docPage'>
                <div className='docContainer'>
                    <h1>Политика конфиденциальности</h1>
                    <Privacy/>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPage