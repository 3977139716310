import React from 'react'

const Question = (props) => {

    let text = props.text.map(m => 
        <p>{m}</p>
        )

    return (
        <div>
        <h3>{props.title}</h3>
        {text}
        </div>
    )
}

export default Question