import { connect } from 'react-redux';
import ClientPage from './ClientPage';
import {setVisiblePopover} from '../../redux/contactForm'

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            state: state.client.ru,
            apps: state.apps
        }
    } else { 
        return {
            state: state.client.en,
            apps: state.apps
        }
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisiblePopover: (visible) => {
            dispatch(setVisiblePopover(visible))
    }
}}

const ClientPageContainer = connect(mapStateTooProps, mapDispatchToProps)(ClientPage)

export default ClientPageContainer