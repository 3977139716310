import React from 'react'
import { animateScroll as scroll } from "react-scroll";
import fairill from './../../resources/rupor.png'

const Press = (props) => {
    scroll.scrollToTop()

    let pressItems = props.state.arr.map(m =>
        <a className='tg-press-card' href={m.source.url}>
            <div className='tg-press-img'>
                <img src={m.img}/>
            </div>
            <div className='tg-press-description'>
                <h3>{m.title}</h3>
                <h6>{m.subtitle}</h6>
                <div className='tg-press-source'>
                    <img src={m.source.logo} alt={m.source.name} />
                    <p>{m.source.name}</p>
                </div>
            </div>
        </a>
    )

    return (
        <div>
            <div className='tg-press-fairill'>
                <img src={fairill} alt="rupor" />
            </div>
            <div className='primary'>
                <div className='grid-container'>
                    {pressItems}
                </div>
            </div>
        </div>
    )
}

export default Press