import { connect } from 'react-redux';
import Business from './Business';
import {setVisiblePopover} from '../../redux/contactForm'

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            state: state.business.ru,
            apps: state.apps
        }
    } else { 
        return {
            state: state.business.en,
            apps: state.apps
        }
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisiblePopover: (visible) => {
            dispatch(setVisiblePopover(visible))
    }
}}

const BusinessContainer = connect(mapStateTooProps, mapDispatchToProps)(Business)

export default BusinessContainer