import React from 'react'

const LanguageSelector = (props) => {

    let changeLanguage = (e) => {
        let newLanguage = e.target.value
        props.selectLanguage(newLanguage)
        props.showSelector()
    }

    let showSelector = () => {
        if (props.lang.visibleSelector === true) {
            props.showSelector(false)
        } else { props.showSelector(true) }

    }

    let languagesList = props.lang.languages.map(m =>
        <button onClick={changeLanguage} value={m.languageCode}>{m.language}</button>)

    return (
        <div>
            <div className='langSelector'>
                <span onClick={showSelector}><p>{props.lang.currentLanguage}</p> <i onClick={showSelector} className="material-icons">arrow_drop_down</i></span>
            </div>
            {props.lang.visibleSelector
                ?
                <div>
                    <div className='langSelectorTrue'>{languagesList}</div>
                    <div className='langBackDrop' onClick={showSelector}></div>
                </div>
                : <div></div>
            }
        </div>
    )
}

export default LanguageSelector