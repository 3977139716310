import React from "react";
import { Formik, Field, Form } from "formik";
import BasicFormSchema from "./BasicFormSchema";
import * as axios from 'axios'

const ContactForm = (props) => (

    <div className="formContainer">
        <h1>{props.state.title}</h1>
        {
            props.bools.sendError
                ? <div className='sendError'>
                    {props.state.fields.errorMessage}
                </div>
                : <span></span>
        }

        <Formik

            initialValues={{
                name: "",
                phone: "",
                email: "",
                message: ""
            }}

            validationSchema={BasicFormSchema}

            onSubmit={(values) => {
                axios.post('/send-mail', values)
                    .then(props.setVisibleForm(false))
            }}

            render={({ errors, touched }) => (
                <Form id='contactForm' className='contactForm'>
                    <label htmlFor="name">{props.state.fields.name.label}</label>
                    <Field name="name" placeholder={props.state.fields.name.placeholder} type="text" />
                    {errors.name && touched.name && (<div className='contactError'>{errors.name}</div>)}

                    <label htmlFor="email">{props.state.fields.email.label}</label>
                    <Field name="email" placeholder={props.state.fields.email.placeholder} type="email" />
                    {errors.email && touched.email && <div className='contactError'>{errors.email}</div>}

                    <label htmlFor="phone">{props.state.fields.phone.label}</label>
                    <Field name="phone" placeholder={props.state.fields.phone.placeholder} type="text" />
                    {errors.phone && touched.phone && (<div className='contactError'>{errors.phone}</div>)}

                    <label htmlFor="message">{props.state.fields.message.label}</label>
                    <Field component="textarea" name="message" placeholder={props.state.fields.message.placeholder} type="text" />
                    {errors.message && touched.message && (<div className='contactError'>{errors.message}</div>)}

                    <button className="g-recaptcha"
                        data-sitekey="6LdGm7wZAAAAAA3x-F-qtjSOv6K6drNv0q54JaJT"
                        data-callback='onSubmit'
                        data-action='submit'
                        type="submit">{props.state.fields.submit}</button>
                </Form>
            )}
        />
    </div>
);

export default ContactForm;