import { connect } from 'react-redux'
import { setVisible } from '../../redux/mainMenu'
import Footer from './Footer'

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            state: state.footer.ru
        }
    } else { 
        return {
            state: state.footer.en
        }
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisible: (visible) => {
            dispatch(setVisible(visible))
        }
    }
}

const FooterContainer = connect(mapStateTooProps, mapDispatchToProps)(Footer)

export default FooterContainer