let initialState = {
    ru: {
        sign: 'Первая франшиза Кикшеринга в СНГ',
        links: {
            privacy: 'Политика конфиденциальности',
            joining: 'Договор аренды',
            faq: 'FAQ',
            certificate: 'Сертификаты toGO'
        }
    },
    en: {
        sign: 'The first kicksharing franchise in the CIS',
        links: {
            privacy: 'Privacy policy',
            joining: 'Lease contract',
            faq: 'FAQ',
            certificate: 'toGO certificates'
        }
    }
}

const footerReducer = (state = initialState, action) => {
    return state
}

export default footerReducer