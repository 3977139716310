import fairill from './../resources/fair-ill.svg'
import like from './../resources/business/like.svg'
import awesome from './../resources/business/awesome.svg'
import scooter from './../resources/business/bsns-scooter.png'
import tracker from './../resources/business/bsns-tracker.png'
import certificate from './../resources/certificates/CertificateTeltonika.png'
import presentation from './../resources/presentation-togo.pdf'
import price from './../resources/price-sept-21.pdf'
import greenWave from './../resources/green-wave.svg'

import check from './../resources/business/features/check.svg'
import lockStar from './../resources/business/features/lock-star.svg'
import app from './../resources/business/features/app.svg'
import dash from './../resources/business/features/dash.svg'
import judge from './../resources/business/features/judge.svg'
import learn from './../resources/business/features/learn.svg'
import onlineCheck from './../resources/business/features/online-check.svg'
import fire from './../resources/business/features/fire.svg'
import support from './../resources/business/features/support.svg'
import group from './../resources/business/features/group.svg'
import business from './../resources/business/features/business.svg'
import loyality from './../resources/business/features/loyality.svg'
import heart from './../resources/business/features/heart.svg'
import supportOne from './../resources/business/features/support-1.svg'
import marketing from './../resources/business/features/marketing.svg'

let initialState = {
    ru: {
        divider: greenWave,
        fair: {
            title: {
                span: 'Первая',
                body: 'в России и странах СНГ франшиза кикшеринга',
            },
            subtitle: 'Запуск кикшеринга в Вашем городе.',
            fairill: fairill,
        },
        connectSign: 'Обратная связь',
        offer: {
            bsnsFeatures: [
                {
                    class: 'tg-bsns-feature-card-one',
                    img: like,
                    title: {
                        name: 'Еквайринг -',
                        sale: '2.5%',
                        original: '3.9%'
                    }
                },
                {
                    class: 'tg-bsns-feature-card-two',
                    img: awesome,
                    title: {
                        name: 'Окупаемость проекта от 6 месяцев',
                        sale: '',
                        original: ''
                    }
                }
            ],
            items: {
                btnText: 'Подробнее в WhatsApp',
                kicksharing: {
                    title: 'Запуск кикшеринга в вашем городе с toGO',
                    tasks: [
                        {
                            emoji: '🚀',
                            p: 'От 50 самокатов'
                        }, {
                            emoji: '💸',
                            p: 'Инвестиции от',
                            sale: '1 900 000 ₽',
                            original: '2 500 000 ₽'
                        }, {
                            emoji: '⌛️',
                            p: 'Окупаемость от 6 месяцев'
                        },
                    ],
                    scooters: {
                        title: 'Самокаты используемые в сервисе:',
                        scooters: [
                            {
                                name: 'Ninebot Max G30',
                            },{
                                name: 'Ninebot Max Pro',
                            },{
                                name: 'Ninebot Max Plus',
                            }
                        ],
                    }
                },
                scooter: {
                    img: scooter,
                    title: 'Самокат Segway Ninebot Max',
                    sale: '38 000₽',
                    original: '47 500₽',
                    span: 'Самокат полностью подготовлен для работы в городе',
                    p: 'Офицальная прошивка самоката сопрягает модуль Teltonika таким образом, что при удалении модуля самокат теряет работоспособность. Аналогов по антиугонному решению нет ни у одного производителя'
                },
                tracker: {
                    img: tracker,
                    title: 'Оригинальный трекер GPS',
                    ul: [
                        {
                            quntity: '50-100 шт',
                            price: '5 200₽'
                        },
                        {
                            quntity: '100-300 шт',
                            price: '5 000₽'
                        }, {
                            quntity: '300-500 шт',
                            price: '4 800₽'
                        }, {
                            quntity: 'Свыше 500 шт',
                            price: '4 600₽'
                        }
                    ],
                    certificate: {
                        title: 'Сертификат партнера Teltonika',
                        img: certificate,
                    },
                },
                contact: {
                    title: 'Планируете запуск сервиса и у Вас остались вопросы по стоимости или условиям',
                    subtitle: 'Ознакомьтесь с нашими презетациями:',
                    pres: {
                        pres: presentation,
                        p: 'Скачать презентацию toGO'
                    },
                    price: {
                        price: price,
                        p: 'Скачать прайс-лист'
                    }
                }
            }
        },
        features: {
            title: 'Подключаясь к сети toGO вы получаете множество преимуществ:',
            ul: [
                {
                    img: check,
                    p: 'Отсутствие взносов'
                }, {
                    img: lockStar,
                    p: 'Авторская система защиты от кражи'
                }, {
                    img: app,
                    p: 'Мобильные  приложения: IOS/ Android'
                }, {
                    img: dash,
                    p: 'Административная панель'
                }, {
                    img: judge,
                    p: 'Юридическая поддержка'
                }, {
                    img: learn,
                    p: 'Обучение сотрудников'
                }, {
                    img: onlineCheck,
                    p: 'Онлайн-касса'
                }, {
                    img: fire,
                    p: 'Узнаваемый бренд'
                }, {
                    img: support,
                    p: 'Подключение к сервису центра поддержки клиентов'
                }, {
                    img: group,
                    p: 'Доступ к базе клиентов'
                }, {
                    img: business,
                    p: 'Бизнес план'
                }, {
                    img: loyality,
                    p: 'Реферальная система лояльности клиентов'
                }, {
                    img: heart,
                    p: 'Бонусная программа для пользователей'
                }, {
                    img: supportOne,
                    p: 'Интеграция со страховой компанией'
                }, {
                    img: marketing,
                    p: 'Маркетинговая поддержка'
                },
            ]
        },
        contactBlockTitle: 'Свяжитесь с нами удобным способом',
        cities: {
            number: 12,
            title: 'городов запустили сервис toGO',
            rides: '1 000 000+',
            ridesAlt: '👾 поездок'
        },
        services: {
            title: 'Более',
            number: '15',
            text: 'сервисов кикшеринга запущено командой toGO',
        },
        contactUs: {
            title: 'Легкий запуск бизнеса по прокату самокатов 🚀',
            subtitle: 'Мы гарантируем Вам - лучшее  финансовое  и техническое  решение для бизнеса',
            nav: false,
            button: '🛴 Открытие Кикшеринга в вашем городе',
            style: 'ownBrand'
        }
    },
    en: {
        divider: greenWave,
        fair: {
            title: {
                span: 'First',
                body: 'kicksharing franchaise in Russia and CIS countries',
            },
            subtitle: 'Launch kicksharing in your city',
            fairill: fairill,
        },
        offer: {
            bsnsFeatures: [
                {
                    class: 'tg-bsns-feature-card-one',
                    img: like,
                    title: {
                        name: 'Acquiring -',
                        sale: '2.5%',
                        original: '3.9%'
                    }
                },
                {
                    class: 'tg-bsns-feature-card-two',
                    img: awesome,
                    title: {
                        name: 'Payback from 6 months',
                        sale: '',
                        original: ''
                    }
                }
            ],
            items: {
                btnText: 'Contact us for details',
                kicksharing: {
                    title: 'Launch kicksharing in your city with toGO',
                    tasks: [
                        {
                            emoji: '🚀',
                            p: 'From 50 scooters'
                        }, {
                            emoji: '💸',
                            p: 'Investments from',
                            sale: '1 900 000 ₽',
                            original: '2 500 000 ₽'
                        }, {
                            emoji: '⌛️',
                            p: 'Payback from 6 months'
                        },
                    ],
                    scooters: {
                        title: 'Scooters used in the service',
                        scooters: [
                            {
                                name: 'Ninebot Max G30',
                            },{
                                name: 'Ninebot Max Pro',
                            },{
                                name: 'Ninebot Max Plus',
                            }
                        ],
                    }
                },
                scooter: {
                    img: scooter,
                    title: 'Segway Ninebot Max',
                    sale: '38 000₽',
                    original: '47 500₽',
                    span: 'Scooter is fully prepared for work in the city',
                    p: 'The official firmware of the scooter connects the Teltonika module in such a way that when the module is removed, the scooter loses its functionality. None of the manufacturers have analogues in terms of the anti-theft solution'
                },
                tracker: {
                    img: tracker,
                    title: 'Original GPS tracker',
                    ul: [
                        {
                            quntity: '50-100 pcs',
                            price: '5 200₽'
                        },
                        {
                            quntity: '100-300 pcs',
                            price: '5 000₽'
                        }, {
                            quntity: '300-500 pcs',
                            price: '4 800₽'
                        }, {
                            quntity: 'From 500 pcs',
                            price: '4 600₽'
                        }
                    ],
                    certificate: {
                        title: 'Teltonika partner certificate',
                        img: certificate,
                    },
                },
                contact: {
                    title: 'Planning launch the service or you still have questions about the cost or conditions',
                    subtitle: 'Check out our presentations:',
                    pres: {
                        pres: presentation,
                        p: 'Download presentation toGO'
                    },
                    price: {
                        price: price,
                        p: 'Download price'
                    }
                }
            }
        },
        features: {
            title: 'If you connect to the toGO network, you will get many benefits:',
            ul: [
                {
                    img: check,
                    p: 'No contributions'
                }, {
                    img: lockStar,
                    p: 'Authorized anti-theft protection system'
                }, {
                    img: app,
                    p: 'Mobile apps on IOS & Android'
                }, {
                    img: dash,
                    p: 'Admin panel'
                }, {
                    img: judge,
                    p: 'Legal support'
                }, {
                    img: learn,
                    p: 'Employee training'
                }, {
                    img: onlineCheck,
                    p: 'Online checkout'
                }, {
                    img: fire,
                    p: 'Famous brand'
                }, {
                    img: support,
                    p: 'Connecting to the customer support center service'
                }, {
                    img: group,
                    p: 'Access to the customer base'
                }, {
                    img: business,
                    p: 'Business plan'
                }, {
                    img: loyality,
                    p: 'Referral customer loyalty system'
                }, {
                    img: heart,
                    p: 'Bonus system for users'
                }, {
                    img: supportOne,
                    p: 'Integration with an insurance company'
                }, {
                    img: marketing,
                    p: 'Marketing support'
                },
            ]
        },
        contactBlockTitle: 'Contact us in a convenient way',
        connectSign: 'Contact us',
        cities: {
            number: 12,
            title: 'cities launched toGO kicksharing',
            rides: '1 000 000+',
            ridesAlt: '👾 rides'
        },
        services: {
            title: 'More than',
            number: '15',
            text: 'kicksharing services launched by toGO team',
        },
        contactUs: {
            title: 'Easy way to begin kicksharing business 🚀',
            subtitle: 'We guarantee you the best financial and technical solution for your business',
            nav: false,
            button: '🛴 Launch licksharing in your city',
            style: 'ownBrand'
        }
    }
}

const businessReducer = (state = initialState, action) => {
    return state
}

export default businessReducer