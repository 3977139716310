import { connect } from 'react-redux';
import {setVisiblePopover} from '../../redux/contactForm'
import Certificates from './Certificates';

let mapStateTooProps = (state) => {
    return {
        state: state.certificates
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisiblePopover: (visible) => {
            dispatch(setVisiblePopover(visible))
    }
}}

const CertificatesContainer = connect(mapStateTooProps ,mapDispatchToProps)(Certificates)

export default CertificatesContainer