import React from 'react'
import ninebot from './../../resources/partners/ninebot.png'
import teltonika from './../../resources/partners/teltonika.png'
import sunwheel from './../../resources/partners/sunwheel.png'
import togogeo from './../../resources/partners/togogeo.png'
import boomerang from './../../resources/partners/boomerang.png'
import togoshop from './../../resources/partners/togoshop.png'
import allianz from './../../resources/partners/allianz.png'
import okai from './../../resources/partners/okai.png'
import knot from './../../resources/partners/knot.png'


const Partners = (props) => {
    return (
        <div className='partnersContainer'>
            {/* <h3>{props.partnersTitle}</h3> */}
            <div className='partners'>
                <a href="https://okai.co/" target="_blank" rel="noopener noreferrer">
                <img src={okai} alt="okai" />
                </a>
                <a href="https://www.ninebot.com/" target="_blank" rel="noopener noreferrer">
                <img src={ninebot} alt="ninebot" />
                </a>
                <a href="https://www.knotcity.com/" target="_blank" rel="noopener noreferrer">
                <img src={knot} alt="knot" />
                </a>
                <a href="https://www.allianz.com/en.html" target="_blank" rel="noopener noreferrer">
                <img src={allianz} alt="allianz"/>
                </a>
                <a href="https://togoshop.ge/" target="_blank" rel="noopener noreferrer">
                <img src={togoshop} alt="togoshop"/>
                </a>
                {/* <a href="https://to-go.ge/" target="_blank" rel="noopener noreferrer">
                <img src={togogeo} alt="toGO GEO"/>
                </a> */}
                <a href="https://teltonika-iot-group.com/" target="_blank" rel="noopener noreferrer">
                <img src={teltonika} alt="teltonika"/>
                </a>
            </div>
        </div>
    )
}

export default Partners