import React from 'react'
import MainSection from './Section'
import Partners from './Partners'
import { NavLink } from 'react-router-dom'

import { animateScroll as scroll } from "react-scroll";
import CatchBlockWithBg from '../other/CatchBlockWithBg'
import MainFeatures from './MainFeatures'
import fairill from './../../resources/fair-ill.svg'

import scooter from './../../resources/business/bsns-scooter.png'
import tracker from './../../resources/business/bsns-tracker.png'

import like from './../../resources/business/like.svg'
import awesome from './../../resources/business/awesome.svg'

import check from './../../resources/business/features/check.svg'
import lockStar from './../../resources/business/features/lock-star.svg'
import app from './../../resources/business/features/app.svg'
import dash from './../../resources/business/features/dash.svg'
import judge from './../../resources/business/features/judge.svg'
import learn from './../../resources/business/features/learn.svg'
import onlineCheck from './../../resources/business/features/online-check.svg'
import fire from './../../resources/business/features/fire.svg'
import support from './../../resources/business/features/support.svg'
import group from './../../resources/business/features/group.svg'
import business from './../../resources/business/features/business.svg'
import loyality from './../../resources/business/features/loyality.svg'
import heart from './../../resources/business/features/heart.svg'
import supportOne from './../../resources/business/features/support-1.svg'
import marketing from './../../resources/business/features/marketing.svg'

import price from './../../resources/price-sept-21.pdf'
import presentation from './../../resources/presentation-togo.pdf'

import tg from './../../resources/social/tg-logo.svg'
import waLogo from './../../resources/wa-logo.svg'
import mail from './../../resources/mail-icon.svg'

import certificate from './../../resources/certificates/CertificateTeltonika.png'


const Business = (props) => {
    scroll.scrollToTop()

    let openPopup = () => {
        props.setVisiblePopover(true)
    }

    let bsnsFeatures = props.state.offer.bsnsFeatures.map(m =>
        <div className={m.class}>
            <img src={m.img} alt="toGO img" />
            <h4>{m.title.name} <span className='tg-bsns-sale'>{m.title.sale}</span> <span style={{ fontSize: "14px", textDecoration: "line-through", opacity: "0.8" }}>{m.title.original}</span></h4>
        </div>
    )

    let scooters = props.state.offer.items.kicksharing.scooters.scooters.map(m =>
        <li><h5>{m.name}</h5></li>
    )

    let benefits = props.state.features.ul.map(m =>
        <div className='sm-horizontalCard'>
            <img src={m.img} alt="toGO" />
            <h6>{m.p}</h6>
        </div>
    )

    return (
        <div>
            <div className='primary'>
                <div className='tg-bsns-fair'>
                    <div>
                        <h1><span>{props.state.fair.title.span}</span> {props.state.fair.title.body}</h1>
                        <h4>{props.state.fair.subtitle}</h4>
                        <div className='tg-bsns-contacts'>
                            <a href="https://wa.me/79775388101" target='_blank'>
                                <img src={waLogo} alt="whatsapp" />
                            </a>
                            <a href="https://t.me/togokicksharing" target='_blank'>
                                <img src={tg} alt="telegram" />
                            </a>
                            <a href="mailto:am@to-go.life">
                                <img src={mail} alt="email" />
                            </a>
                            <button className='violetBtn' onClick={openPopup}>{props.state.connectSign}</button>
                        </div>
                    </div>
                    <img src={fairill} alt="toGo logo" />
                </div>

                <div className='tg-bsns-grid-view'>
                    {bsnsFeatures}
                    <div className='kicksharingCard'>
                        <h3>{props.state.offer.items.kicksharing.title}</h3>
                        <ul className='tg-emoji-list'>
                            <li><span>{props.state.offer.items.kicksharing.tasks[0].emoji}</span><p>{props.state.offer.items.kicksharing.tasks[0].p}</p></li>
                            <li><span>{props.state.offer.items.kicksharing.tasks[1].emoji}</span><p>{props.state.offer.items.kicksharing.tasks[1].p} <span style={{ marginLeft: "4px", backgroundColor: "#EF123A", padding: "4px", borderRadius: "4px", fontSize: "14px", transform: "rotate(-4deg)" }}>{props.state.offer.items.kicksharing.tasks[1].sale}</span> <span style={{ fontSize: "12px", textDecoration: "line-through", opacity: "0.8" }}>{props.state.offer.items.kicksharing.tasks[1].original}</span></p></li>
                            <li><span>{props.state.offer.items.kicksharing.tasks[2].emoji}</span><p>{props.state.offer.items.kicksharing.tasks[2].p}</p></li>
                        </ul>
                        <h5>{props.state.offer.items.kicksharing.scooters.title}</h5>
                        <ul className='tg-flat-list'>
                            {scooters}
                        </ul>
                        <a href="https://wa.me/79775388101" target='_blank' rel="noopener noreferrer"><button className='greenBtn'>{props.state.offer.items.btnText} <span class="material-icons">open_in_new</span></button></a>
                    </div>

                    <div className='scooterCard'>
                        <img src={props.state.offer.items.scooter.img} alt="scooter" />
                        <h3>{props.state.offer.items.scooter.title}</h3>
                        <div>
                            <h4>{props.state.offer.items.scooter.sale}</h4>
                            <h5>{props.state.offer.items.scooter.original}</h5>
                        </div>
                        <div><span class="material-icons">build</span> {props.state.offer.items.scooter.span}</div>
                        <p>{props.state.offer.items.scooter.p}</p>
                        <a href="https://wa.me/79775388101" target='_blank' rel="noopener noreferrer"><button className='violetBtn'>{props.state.offer.items.btnText} <span class="material-icons">open_in_new</span></button></a>
                    </div>

                    {/* <div className='trackerCard'>
                        <img src={tracker} alt="scooter" />
                        <h3>Оригинальный трекер GPS</h3>
                        <ul className='tg-flat-list'>
                            <li><h5>50-100 шт <br /> <b>5 200₽</b></h5></li>
                            <li><h5>100-300 шт <br /> <b>5 000₽</b></h5></li>
                            <li><h5>300-500 шт <br /> <b>4 800₽</b></h5></li>
                            <li><h5>Свыше 500 шт <br /> <b>4 600₽</b></h5></li>
                        </ul>
                        <a href={certificate} target='_blank' rel="noopener noreferrer"><span class="material-icons">loyalty</span><h5>Сертификат партнера Teltonika</h5></a>
                        <a href="https://wa.me/79775388101" target='_blank' rel="noopener noreferrer"><button className='violetBtn'>Подробнее в WhatsApp <span class="material-icons">open_in_new</span></button></a>
                    </div> */}

                    {/* <div className='contactCard'>
                        <h4>Планируете запуск сервиса и у Вас остались вопросы по стоимости или условиям</h4>
                        <h5>Ознакомьтесь с нашими презетациями:</h5>
                        <a href={presentation} target='_blank' rel="noopener noreferrer"><button className='violetBtn'>Скачать презентацию toGO<span class="material-icons">get_app</span></button></a>
                        <a href={price} target='_blank' rel="noopener noreferrer"><button className='violetStrokedBtn'>Скачать прайс-лист<span class="material-icons">get_app</span></button></a>
                    </div> */}
                </div>
                <h2 className='sectionTitle'>{props.state.features.title}</h2>
                <div className='col-2'>
                    {benefits}
                    {/* <NavLink to='/business/kicksharing' className='sm-horizontal-move'>
                        <h6>Подробнее о запуске кикшеринга</h6>
                        <img src={arrow} alt="arrow-right" />
                    </NavLink> */}
                </div>
                <div className='tg-bsns-contacts-block'>
                    <h2>{props.state.contactBlockTitle}</h2>
                    <div>
                        <a href="https://wa.me/79775388101" target='_blank'>
                            <img src={waLogo} alt="whatsapp" />
                        </a>
                        <a href="https://t.me/togokicksharing" target='_blank'>
                            <img src={tg} alt="telegram" />
                        </a>
                        <a href="mailto:am@to-go.life">
                            <img src={mail} alt="email" />
                        </a>
                        <button className='violetBtn' onClick={openPopup}>{props.state.connectSign}</button>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#262490", marginTop: "36px", paddingTop: "36px", paddingBottom: "36px" }}>
                <div className='primary'>
                    <div className='mainFeaturesContainer'>
                        <div className='citiesCard'>
                            <div>
                                <img src={props.state.divider} alt="Wave" />
                                <div className='flexbox'>
                                    <h2>{props.state.cities.number}</h2>
                                    <h3>{props.state.cities.title}</h3>
                                </div>
                            </div>
                            <div className='flexbox'>
                                <h4> {props.state.cities.rides} <span>{props.state.cities.ridesAlt}</span></h4>
                            </div>
                        </div>
                        <div>
                            <div className='projectCard'>
                                <div className='flexbox'>
                                    <h3>{props.state.services.title}</h3>
                                    <img src={props.state.divider} alt="Wave" />
                                </div>
                                <div className='flexbox'>
                                    <h2>{props.state.services.number}</h2>
                                    <p>{props.state.services.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Partners partnersTitle={props.state.partnersTitle} />
            <CatchBlockWithBg setVisiblePopover={props.setVisiblePopover} state={props.state.contactUs} />
        </div>
    )
}

export default Business