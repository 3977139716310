import { connect } from 'react-redux';
import {setVisiblePopover} from '../../redux/contactForm'
import Accreditation from './Accreditation';

let mapStateTooProps = (state) => {
    return {
        state: state.accreditation
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisiblePopover: (visible) => {
            dispatch(setVisiblePopover(visible))
    }
}}

const AccreditationContainer = connect(mapStateTooProps ,mapDispatchToProps)(Accreditation)

export default AccreditationContainer