import React from 'react'

const Privacy = (props) => {


        return (
                <div>
                        <div>Настоящая Политика конфиденциальности (далее - Политика) действует в
                        отношении всей информации, которую Общество с ограниченной ответственностью «ТУ
                        ГОУ» (ИНН 6164134251, ОГРН 1216100004031, юридический адрес: РФ, 344002, г. Ростов-на-Дону, 
                        ул. Шоссейная 56 Б, стр. Э1, к.8) (далее - Компания),
                        получает о Пользователе в процессе регистрации, авторизации и иного использования
                        Сайта, Приложения toGO, Сервиса toGO в соответствии с размещенными на Сайте и в
                        Приложении toGO Пользовательским соглашением и Договором присоединения.
                        Выраженное в соответствии с Политикой согласие Пользователя на обработку
                        предоставляемых им Компании персональных данных и иной информации, считается,
                        одновременно, предоставленным Пользователем указанным в Политике третьим лицам,
                        привлекаемым Компанией для содействия в исполнении Пользовательского соглашения и
                        Договора присоединения.
</div>
                        <div>Использование Сайта, Приложения toGO, Сервиса toGO
                        (в том числе,
                        осуществление Пользователем регистрации, авторизации) означает безоговорочное
                        согласие Пользователя с Политикой и всеми указанными в ней условиями обработки его
                        персональных данных и иной информации; в случае несогласия с Политикой и всеми ее
                        условиями Пользователь должен воздержаться от использования Сайта, Приложения toGO,
                        Сервиса toGO.
</div>



                        <h2>Персональные данные и иная информация Пользователя, которую получает
                        и обрабатывает Компания
</h2>
                        <div>1.1. При регистрации, авторизации Пользователя, а также при использовании Сайта,
                        Приложения toGO, Сервиса toGO, осуществлении оплат, проведении опросов, рассылке
                        информационных и рекламных сообщений и во всех иных случаях, предусмотренных
                        Пользовательским соглашением и Договором присоединения, Компания может запросить
                        у Пользователя (п.1.1.1. Политики) и/или получить автоматически (п.1.1.2. Политики)
                        следующую информацию о Пользователе:
</div>
                        <div>1.1.1. имя, номер мобильного телефона, адрес электронной почты,
                        информацию о логине и пароле для доступа к отдельным функциям Сайта,
                        Приложения toGO, Сервиса toGO, историю пользования Сервисом toGO
                        (информацию о количестве, стоимости, времени и порядке произведенных
                        Пользователями заказов на услуги Сервиса toGO и их оплате, в том числе данные о
                        банковском счете и/или счете банковской карты), информацию об участии в
                        рекламных акциях, информацию о подписке на информационную рассылку или
                        материалы службы поддержки), реквизиты банка для возврата денежных средств, а
                        также иные данные и информация;
</div>
                        <div>1.1.2. информация, которая автоматически передается Компании в процессе
                        использования Сайта, Приложения toGO, Сервиса toGO, с помощью установленного
                        на устройстве Пользователя программного обеспечения, в том числе IP-адрес,
                        информация из cookie и tracking bugs, информация о стране и
                        (или) городе
                        нахождения Пользователя, информация об Интернет-браузере Пользователя (или
                        иной программе, с помощью которой осуществляется доступ к Сайту, Приложению
                        toGO, Сервису toGO), время доступа, адрес запрашиваемой страницы об устройствах
                        Пользователя, с помощью которых осуществляется доступ к Сайту, Приложению
                        toGO, Сервису toGO.
</div>
                        <div>1.2. Настоящая Политика применима только к Сайту, Приложению toGO, Сервису
                        toGO. Компания не контролирует и не несет ответственность за сайты и программное
                        обеспечение третьих лиц, на которые Пользователь может перейти по ссылкам, доступным
                        на Сайте, в Приложении toGO. На иных сайтах третьих лиц у Пользователя может
                        собираться или запрашиваться иная информация, а также могут совершаться иные
                        действия, за которые Компания не несет ответственности.
</div>
                        <div>1.3. Компания исходит из того, что Пользователь является совершеннолетним
                        дееспособным лицом, соответствующим требованиям Пользовательского соглашения и
                        Договора присоединения, предоставляет достоверную и достаточную информацию и
                        поддерживает эту информацию в актуальном состоянии. Компания вправе осуществить
                        проверку предоставленной Пользователем информации в соответствии с положениями
                        Пользовательского соглашения и Договора присоединения. В случае предоставления
                        Пользователем недостоверной информации, Компания имеет право приостановить либо
                        отменить регистрацию и/или отказать Пользователю в предоставлении доступа к Сайту,
                        Приложению toGO, Сервису toGO. За предоставление недостоверной информации и
                        возникшие вследствие этого негативные последствия Компания и/или иные третьи лица
                        ответственности не несут. Если использование Сайта, Приложения toGO, Сервиса toGO
                        осуществило несовершеннолетнее и/или недееспособное лицо, то ответственность за такое
                        не санкционированное Компанией использование несут родители, усыновители и иные
                        законные представители несовершеннолетнего и/или недееспособного лица.
</div>
                        <h2>2.Цели сбора и обработки данных и иной информации Пользователя</h2>
                        <div>2.1. Компания использует данные и иную информацию Пользователя для целей
                        заключения и исполнения Пользовательского соглашения, заключения и исполнения
                        Договора присоединения, оказания дополнительных услуг, повышения качества сервиса,
                        участия Пользователя в проводимых Компанией акциях, опросах, исследованиях (включая,
                        но не ограничиваясь проведением опросов, исследований посредством электронной,
                        телефонной и сотовой связи), принятия решений или совершения иных действий,
                        порождающих юридические последствия в отношении Пользователя или других лиц,
                        представления Пользователю информации об оказываемых Компанией услугах,
                        предоставления Компанией консультационных услуг. Указанные цели использования
                        персональных данных распространяются на всю информацию, указанную в пункте 1.1
                        Политики.
</div>
                        <div>2.2. Цели сбора и обработки персональных данных включают, без ограничений,
                        следующие:
</div>
                        <div>2.2.1. регистрацию, идентификацию и авторизацию Пользователя в рамках
                        Сервиса toGO;
</div>
                        <div>2.2.2. заключение и исполнение Пользовательского соглашения и Договора
                        присоединения;
</div>
                        <div>2.2.3. предоставление Пользователю Сервиса toGO, а также любого
                        дополнительного функционала в рамках Сервиса toGO;
</div>
                        <div>2.2.4. обработка запросов Пользователей Компанией в рамках Сервиса toGO;</div>
                        <div>2.2.5. анализ и исследования возможностей улучшения Сервиса toGO;</div>
                        <div>2.2.6. рассылка новостей и информации о продуктах, услугах, специальных
                        предложениях, связанных с Сервисом toGO;
</div>
                        <div>2.2.7. рассылка служебных сообщений (например, для информирования о
                        статусе аренды самоката, восстановления/изменения логина и пароля Пользователя
                        и пр.);
</div>
                        <div>2.2.8. предотвращение и выявление мошенничества и незаконного
                        использования Сервиса toGO;
</div>
                        <div>2.2.9. проведение статистических и иных исследований на основе
                        обезличенных данных.
</div>


                        <h2>3.Условия, способы и порядок обработки персональных данных и иной
                        персональной информации Пользователя
</h2>
                        <div>3.1. Компания использует персональные данные и иную информацию Пользователя
                        только для целей, указанных в Политике и в соответствии с Политикой.
</div>
                        <div>3.2. В отношении персональных данных и иной информации Пользователя
                        Компанией соблюдается конфиденциальность.
</div>
                        <div>3.3. Компания не будет раскрывать третьим лицам, распространять, продавать или
                        иным образом распоряжаться полученными персональными данными и иной информацией,
                        кроме как для целей, способами и в пределах, предусмотренных настоящей Политикой.
</div>
                        <div>3.4. Обработка персональных и иных данных Пользователя осуществляется
                        Компанией в объеме, который необходим для достижения каждой из целей, указанных в
                        разделе 2 Политики, следующими возможными способами: сбор, запись (в том числе на
                        электронные носители), систематизация, накопление, хранение, составление перечней,
                        маркировка, уточнение (обновление, изменение), извлечение, использование, передача
                        (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
                        уничтожение, трансграничная передача персональных данных, получение изображения
                        путем фотографирования, а также осуществление любых иных действий с персональными
                        данными Пользователя с учетом применимого права. Компания вправе осуществлять
                        обработку персональных и иных данных Пользователя как с использованием
                        автоматизированных средств обработки персональных данных Пользователя, так и без
                        использования средств автоматизации.
</div>
                        <div>3.5. Компания вправе передавать предоставленные ей Пользователем персональные
                        данные для их обработки (давать поручение на обработку) (в объеме, необходимом для
                        выполнения Компанией своих обязательств) третьим лицам, в том числе, организациям,
                        которые привлекаются Компанией для осуществления информационной отправки
                        сообщений посредством электронной почты/операторов мобильной связи, осуществляют
                        списание/зачисление денежных средств с/на банковской(-ую) карты(- у)/расчетный счет -
                        кредитным организациям (банкам), платежным системам, операторам мобильной связи,
                        курьерским службам, организациями почтовой связи, включая трансграничную передачу
                        персональных данных Пользователя в письменной либо электронной форме, в случаях и в
                        порядке, предусмотренном соответствующими договорами с указанными третьими
                        лицами, правилами Компании, применимым правом.
</div>
                        <div>3.6. Компания также вправе передавать предоставленные ей Пользователем
                        персональные данные государственным органам, суду, иным уполномоченным органам и
                        организациям, в случаях и в порядке, когда это требуется в соответствии с применяемым к
                        Политике правом.
</div>
                        <div>3.7. Компания гарантирует добросовестную и законную обработку персональных и
                        иных данных Пользователя в соответствии с предусмотренными в разделе 2 Политики
                        целями.
</div>
                        <div>3.8. Компания гарантирует незамедлительное обновление данных Пользователя в
                        случае предоставления им обновленных данных.
</div>
                        <div>3.9. Согласие на обработку персональных данных и иных данных дается
                        Пользователем на бессрочной основе, либо до истечения сроков хранения
                        соответствующей информации или документов, содержащих вышеуказанную
                        информацию, определяемых в соответствии с применимым к Политике правом. По
                        истечении указанного срока персональные данные подлежат уничтожению Компанией.
</div>


                        <h2>4 Изменение или удаление информации Пользователем. Отзыв согласия на
    обработку персональных данных</h2>
                        <div>4.1. Пользователь может в любой момент изменить
                        (обновить, дополнить)
                        предоставленные им персональные данные и иную информацию обратившись к Компании,
                        например, через службу поддержки или с использованием контактов, указанных на Сайте,
                        в Приложении toGO с запросом об изменении (обновлении, дополнении) предоставленной
                        Политика конфиденциальности
                        им ранее информации (Компания изменяет (обновляет, дополняет) предоставленную
                        Пользователем информацию только после проведения применяемой в Компании на момент
                        соответствующего обращения процедурой идентификации Пользователя).
</div>
                        <div>4.2.Пользователь вправе отозвать свое согласие на обработку персональных данных путем
                        направления соответствующего письменного уведомления Компании не менее чем за 30
                        (тридцать календарных) дней до момента отзыва согласия, при этом Пользователь признает
                        и понимает, что доступ к пользованию Сервисами Сайта и Приложения toGO, Сервису
                        toGO не будет предоставляться Компанией с того момента, когда Компания лишилась
                        возможности обрабатывать персональные данные Пользователя.
</div>


                        <h2>5 Защита информации Пользователей</h2>
                        <div>5.1.Компания обеспечивает принятие необходимых и достаточных
                        организационных и технических мер для защиты персональных данных и иной информации
                        Пользователей от неправомерного или случайного доступа, уничтожения, изменения,
                        блокирования, копирования, распространения, а также от иных неправомерных действий с
                        ней третьих лиц.
</div>


                        <h2>6 Файлы cookie</h2>
                        <div>6.1. Для улучшения качества предоставления Сервиса toGO Компания может
                        использовать
                        (временные и постоянные) cookie-файлы, tracking bugs и/или другие
                        технологии сбора не носящих личный характер данных (например, IP-адрес, тип браузера
                        и данные о провайдере службы Интернет (ISP), а также (для Пользователей, которые
                        пользуются услугами Компании через мобильное устройство), уникальный идентификатор
                        устройства, данные об операционной системе и координаты с целью учёта количества
                        Пользователей и их поведения при пользовании Сервисом toGO. Для повышения удобства
                        Пользователей Компания вправе собирать и обрабатывать информацию об общем
                        количестве операций, страниц, просмотренных Пользователем, ссылающихся/исходных
                        страниц, типе платформы, дате/времени фиксирования информации, количестве и месте
                        просмотров данной страницы, просмотра страницы и использованных (поисковых) слов.
</div>
                        <div>6.2. Информация о cookies и tracking bugs:</div>
                        <div>6.2.1.Файл «cookie»
                        - небольшой текстовый файл, отправляемый на браузер
                        устройства Пользователя с используемого Компанией сервера. Cookies содержат
                        информацию, которая позже может быть использована Компанией. Браузер будет хранить
                        эту информацию и передавать ее обратно с каждым запросом Пользователя Компании.
                        Одни значения cookies могут храниться только в течение одной сессии и удаляются после
                        закрытия браузера. Другие, установленные на некоторый период времени, записываются в
                        специальный файл на жестком диске и хранятся на устройстве Пользователя. Cookies
                        используются для идентификации, отслеживания сессий
                        (поддержания состояния) и
                        сохранения информации о Пользователе, включая предпочтения при пользовании
                        Сервисом toGO. Используемые Компанией сookies собирают только анонимные данные.
</div>
                        <div>6.2.2. Файл tracking bugs - это графические объекты, встроенные в веб-страницы или
                        в сообщения e-mail. Tracking bugs используются с различными целями, включая отчёты о
                        количестве Пользователей. Используемые Компанией tracking bugs собирают только
                        анонимные данные.
</div>
                        <div>6.3. Компания может использовать cookies и tracking bugs в целях контроля
                        использования Сервиса toGO, сбора информации неличного характера о Пользователе,
                        сохранения предпочтений и другой информации на устройстве Пользователя для того,
                        чтобы сэкономить время Пользователя, необходимое для многократного введения в формах
                        Сайта, Приложения toGO одной и той же информации, а также в целях отображения
                        содержания в ходе последующих посещений Пользователем Сайта, Приложения toGO.
                        Информация, полученная посредством cookies и tracking bugs, также может использоваться
                        Политика конфиденциальности
                        Компанией для статистических исследований, направленных на корректировку содержания
                        Сайта, Приложения toGO в соответствии с предпочтениями Пользователя.
</div>
                        <div>6.4. Компания может предоставить Пользователю возможность изменить настройки
                        приема файлов cookies и tracking bugs в настройках своего браузера или отключить их
                        полностью, однако в таком случае некоторые функции Сервиса toGO могут работать
                        некорректно.
</div>


                        <h2>7 Внесение изменений в Политику. Согласие Пользователя с Политикой</h2>
                        <div>7.1. Пользователь признает и соглашается, что регистрация Пользователя на Сайте,
                        в Приложении toGO и последующее использование Сервиса toGO, любых его служб,
                        функционала означает безоговорочное согласие Пользователя со всеми пунктами
                        настоящей Политики и безоговорочное принятие ее условий.
</div>
                        <div>7.2. Продолжение Пользователем использования Сервиса toGO после любых
                        изменений и/или дополнений Политики означает его согласие с такими изменениями и/или
                        дополнениями.
</div>
                        <div>7.3. Пользователь обязуется регулярно знакомиться с содержанием Политики в
                        целях своевременного ознакомления с ее изменениями/дополнениями.
</div>
                        <div>7.4. Компания оставляет за собой право по своему усмотрению изменять и (или)
                        дополнять Политику в любое время без предварительного и
                        (или) последующего
                        уведомления Пользователя. Новая редакция Политики вступает в силу с момента ее
                        размещения на Сайте и/или в Приложении toGO, если иное не предусмотрено новой
                        редакцией Политики. Действующая редакция Политики всегда доступна на Сайте и/или в
                        Приложении toGO.
                        Уважаемый Пользователь, если Вы не согласны с положениями Политики,
                        откажитесь от использования Сайта, Приложения toGO, Сервиса toGO.
</div>


                        <h2>8 Заключительные положения</h2>
                        <div>8.1. К Политике и возникающими в связи с применением Политики отношениям
                        между Пользователями и Компанией подлежит применению право Российской Федерации.
</div>
                        <div>8.2. Соглашаясь с условиями Политики, Пользователь дает согласие на обработку
                        персональных и иных данных своей волей и в своем интересе.
                        </div>
                        <div>8.3. Отказ от предоставления персональных данных и иной необходимой для
                        использования Сайта, Приложения toGO, Сервиса toGO информации влечет невозможность
                        для Компании предоставлять Пользователю Сервиса toGO.
                        </div>
                        <div>Дата последнего изменения Политики конфиденциальности 10.03.2020 г.</div>
                </div>
        )
}

export default Privacy