import rosqualityImg from './../resources/certificates/rosquality.png'
import cloudPaymentsImg from './../resources/certificates/cloudpayments.png'
import teltnikaImg from './../resources/certificates/teltonika.png'

import rosquality from './../resources/certificates/RosQualityCertificate.png'
import cloudPayments from './../resources/certificates/CloudCertificate.png'
import teltnika from './../resources/certificates/CertificateTeltonika.png'

let initialState = {
    arr: [
        {
            img: rosqualityImg,
            title: 'Сертификат качества и безопасности мобильного приложения',
            certificate: rosquality,
        },
        {
            img: cloudPaymentsImg,
            title: 'Безопасность платежей c CloudPayments',
            certificate: cloudPayments,
        },
        {
            img: teltnikaImg,
            title: 'Сертификат партнера Teltonika',
            certificate: teltnika
        },
    ]
}

const CertificatesReducer = (state = initialState, action) => {
    return state
}

export default CertificatesReducer