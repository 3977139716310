import React from 'react'

import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import Question from './Question';

const FaqGroup = (props) => {

    let faqs = props.q.map(m =>
        <AccordionItemPanel><Question title={m.title} text={m.p} /></AccordionItemPanel>
    )

    return (
        <div>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {props.title}
                    </AccordionItemButton>
                </AccordionItemHeading>
                {faqs}
            </AccordionItem>
            {faqs}
        </div>
    )
}

export default FaqGroup