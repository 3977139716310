import scheme from './../resources/equipment/scheme.svg'
import wrench from './../resources/equipment/wrench.svg'
import coins from './../resources/other/coins.png'
import manageBlock from './../resources/equipment/manage-block.png'
import scooter from './../resources/equipment/scooter.png'
import appImg from './../resources/equipment/app.png'

import doneall from './../resources/equipment/moduleFeatures/done-all.svg'
import battery from './../resources/equipment/moduleFeatures/battery.svg'
import localpolice from './../resources/equipment/moduleFeatures/local-police.svg'
import lockmanage from './../resources/equipment/moduleFeatures/lock-manage.svg'
import lockopen from './../resources/equipment/moduleFeatures/lock-open.svg'
import multisim from './../resources/equipment/moduleFeatures/multisim.svg'
import place from './../resources/equipment/moduleFeatures/place.svg'
import safety from './../resources/equipment/moduleFeatures/safety.svg'
import settingsinput from './../resources/equipment/moduleFeatures/settings-input.svg'
import signal from './../resources/equipment/moduleFeatures/signal.svg'

import featureScooter from './../resources/kicksharing/features/scooter.svg'
import pin from './../resources/kicksharing/features/pin.svg'

let initialState = {
    cover: {
        className: 'EquipmentCover',
        title: 'Оборудование ',
        span: 'для кикшеринга'
    },

    iotModule: {
        img: manageBlock,
        alt: 'IoT module toGO',
        title: 'Закажите телематический модуль toGO',
        subtitle: 'На это есть несколько причин',
        items: [
            {
                img: scheme,
                alt: 'Scheme',
                title: 'Мы являемся интегратором ведущего европейского производителя IOT - модуля для самокатов',
                subtitle: ''
            },
            {
                img: wrench,
                alt: 'Wrench',
                title: 'IOT-модуль, это не просто трекер, это целое решение, основанное на нашем опыте.',
                subtitle: ''
            },
        ]
    },

    features: {
        title: 'Возможности нашего IOT-модуля:',
        items: [
            {
                img: doneall,
                alt: 'done all',
                title: 'Полностью «читает» все данные, которые передает процессор самоката',

            },
            {
                img: settingsinput,
                alt: 'all settings',
                title: 'Контролирует все процессы, заложенные производителем в самокат',
            },
            {
                img: lockopen,
                alt: 'open lock',
                title: 'Блокировка/разблокировка самоката и мотора колеса',
            },
            {
                img: place,
                alt: 'Location',
                title: 'Контролирует местоположение и трек самоката',
            },
            {
                img: battery,
                alt: 'battery control',
                title: 'Контролирует уровень заряда АКБ',
            },
            {
                img: lockmanage,
                alt: 'lock management',
                title: 'Управляет электромеханическим замком для фиксации самоката на улице',
            },
            {
                img: localpolice,
                alt: 'serurity',
                title: 'Программа «Антиугон»',
            },
            {
                img: safety,
                alt: 'serurity',
                title: 'Антивандальная защита — контроль изменения уровня самоката по высоте и движению вне аренды',
            },
            {
                img: signal,
                alt: 'online',
                title: 'Усиленная антенна , позволяет устанавливать блок не снаружи, как это реализовано в большинстве кикшерингов, а внутрь самоката, что является большим плюсом для антивандальности',
            },

        ]
    },

    lockInfo: {
        img: scooter,
        alt: 'scooter',
        title: 'Электромеханический замок',
        subtitle: 'Мы предлагаем вам электромеханический замок, интегрированный с IOT-модулем'
    },
    solutions: {
        title: 'Преимущества решения',
        items: [
            {
                img: featureScooter,
                alt: 'scooter',
                title: 'Самокат',
                text: 'toGO одни из первых в мире применили  самокат 🛴 «Ninebot Max» в кикшеринге, и на сегодняшний день у этой серии самокатов, так и не появилось достойных конкурентов'

            },
            {
                img: pin,
                alt: 'GPS',
                title: 'GPS модуль контроля и управления самокатом',
                text: 'Наша компания официальный представитель ведущего мирового производителя GPS устройств. Модуль создан при поддержке производителя «Segway-Ninebot». Аналогов этому устройству нет. Принцип защиты самоката от угона: после сопряжения устройства с самокатом в «мозги» и все модули самоката загружается специальная прошивка, при удалении устройства - самокат больше не сможет работать без нашего GPS модуля. Последующая перепрошивка «процессора» или удаление данных - все равно приведёт к ошибкам и неработоспособности  самоката. Выбор очевиден!'

            },
        ],
    },
}

const equipmentReducer = (state = initialState, action) => {
    return state
}

export default equipmentReducer