import React from 'react'
import Partners from './Partners'
import { NavLink } from 'react-router-dom'
import ContactForm from '../ContactForm/ContactForm'

import { animateScroll as scroll } from "react-scroll";



const Development = (props) => {
    debugger
    scroll.scrollToTop()

    let openPopup = () => {
        props.setVisiblePopover(true)
    }

    let contacts = props.state.contactUs.links.map(m =>
        <a href={m.url} target='_blank' rel="noopener noreferrer"><img src={m.img} alt={m.name} /><h4>{m.name}</h4></a>
    )

    let features = props.state.features.map(m =>
        <div className={m.class}>
            <h3>{m.title}</h3>
            <p>{m.p}</p>
        </div>
    )

    let frontend = props.state.tech.frontend.items.map(m =>
        <div className='tg-dv-tech-card'>
            <img src={m.img} alt={m.title} />
            <h3>{m.title}</h3>
        </div>
    )

    let backend = props.state.tech.backend.items.map(m =>
        <div className='tg-dv-tech-card'>
            <img src={m.img} alt={m.title} />
            <h3>{m.title}</h3>
        </div>
    )

    let whyus = props.state.whyus.map(m =>
        <div className={m.class}>
            <h3>{m.title}</h3>
        </div>
    )

    return (
        <div>
            <div className='tg-dv-fair'>
                <div className='tg-dv-fair-container'>
                    <h1>{props.state.fair.title}</h1>
                    <h2>{props.state.fair.subtitle}</h2>
                    <div className='tg-dv-fair-contact'>
                        <h3>{props.state.contactUs.title}</h3>
                        <div>
                            {contacts}
                            <a href='#' onClick={openPopup}><img src={props.state.contactUs.reverceCall.img} alt={props.state.contactUs.reverceCall.name} /><h4>{props.state.contactUs.reverceCall.name}</h4></a>
                        </div>
                    </div>
                </div>
                <div className='tg-dv-features-container'>
                    <div className='tg-dv-add-header'>
                        <h2>{props.state.mission.title}</h2>
                        <div></div>
                    </div>
                    <div className='tg-dv-img-block'>
                        <img src={props.state.mission.img} alt="photo" />
                        <div className='tg-dv-img-block-text'>
                            <div className='tg-dv-icon-item'>
                                <span class="material-icons">lightbulb</span>
                                <h4>{props.state.mission.p1}</h4>
                            </div>
                            <div className='tg-dv-icon-item'>
                                <span class="material-icons">biotech</span>
                                <h4>{props.state.mission.p2}</h4>
                            </div>
                            <div className='tg-dv-icon-item'>
                                <span class="material-icons">volunteer_activism</span>
                                <h4>{props.state.mission.p3}</h4>
                            </div>
                            <div className='tg-dv-icon-item'>
                                <span class="material-icons">diamond</span>
                                <h4>{props.state.mission.p4}</h4>
                            </div>
                            <div className='tg-dv-icon-item'>
                                <span class="material-icons">connect_without_contact</span>
                                <h4>{props.state.mission.p5}</h4>
                            </div>
                            <div className='tg-dv-icon-item'>
                                <span class="material-icons">school</span>
                                <h4>{props.state.mission.p6}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tg-dv-features-container'>
                    <div className='tg-dv-items-grid'>
                        {features}
                    </div>
                </div>
            </div>
            <div className='tg-dv-container'>
                <div>
                    <h1 className='tg-dv-header'>{props.state.tech.title}</h1>
                    <div>
                        <div className='tg-dv-add-header'>
                            <h4>{props.state.tech.frontend.title}</h4>
                            <div></div>
                        </div>
                        <div className='tg-dv-2col'>
                            {frontend}
                        </div>
                    </div>
                    <div>
                        <div className='tg-dv-add-header'>
                            <h4>{props.state.tech.backend.title}</h4>
                            <div></div>
                        </div>
                        <div className='tg-dv-2col'>
                            {backend}
                        </div>
                    </div>
                    <div>
                        <div className='tg-dv-add-header'>
                            <h4>{props.state.tech.mobile}</h4>
                            <div></div>
                        </div>
                        <div className='tg-dv-2col'>
                            <div className='tg-dv-tech-card'>
                                <img src={props.state.tech.ios.img} alt={props.state.tech.ios.tech} />
                                <h3>{props.state.tech.ios.title} — {props.state.tech.ios.tech}</h3>
                            </div>
                            <div className='tg-dv-tech-card'>
                                <img src={props.state.tech.android.img} alt={props.state.tech.android.tech} />
                                <h3>{props.state.tech.android.title} — {props.state.tech.android.tech}</h3>
                            </div>
                        </div>
                        <div className='tg-dv-add-header'>
                            <h4>{props.state.tech.embeddedTitle}</h4>
                            <div></div>
                        </div>
                        <div className='tg-dv-2col'>
                            <div className='tg-dv-tech-card'>
                                <img src={props.state.tech.iot.img} alt={props.state.tech.iot.tech} />
                                <h3>{props.state.tech.iot.title} — {props.state.tech.iot.tech}</h3>
                            </div>
                            <div className='tg-dv-tech-card'>
                                <img src={props.state.tech.embedded.img} alt={props.state.tech.embedded.tech} />
                                <h3>{props.state.tech.embedded.title} — {props.state.tech.embedded.tech}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h1 className='tg-dv-header'>{props.state.whyusTitle}</h1>
                    <div className='tg-dv-items-grid'>
                        {whyus}
                    </div>
                </div>
            </div>
            <div className='tg-pb-fair-gray'>
                <div className='tg-pb-container-form'>
                    <div className='tg-pb-catch'>
                        <h2>{props.state.contactUs.title}</h2>
                        <div>
                            {contacts}
                            <a href='#' onClick={openPopup}><img src={props.state.contactUs.reverceCall.img} alt={props.state.contactUs.reverceCall.name} /><h4>{props.state.contactUs.reverceCall.name}</h4></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Development