import React from 'react'
import { NavLink } from 'react-router-dom'

import { animateScroll as scroll } from "react-scroll";
import Faq from './../faq/FaqContainer'
import instagramGrey from './../../resources/social/instagram-gray-sm.svg'
import appImg from './../../resources/client/app.png'

const ClientPage = (props) => {
    scroll.scrollToTop()

    let InsrtuctionItems = props.state.instruction.items.map(m => (
        <li>
            <span>{m.emoji}</span>
            <h6>{m.title}</h6>
        </li>
    )
    )

    let socialItems = props.state.social.items.map(m => (
        <a href={m.link}><img src={m.icon} alt={m.alt} /></a>
    ))

    let presence = props.state.presence.map(m => (
        <li>
            <span>
                <img src={m.img} alt={m.city} />
                <h6>{m.city}</h6>
            </span>
            <a href={m.url} target='_blank' noopener noreffer>
                <img src={instagramGrey} alt="instagram" />
            </a>
        </li>
    ))

    let apps = props.apps.apps.map(m=>
        <a href={m.url} rel="noopener noreferrer"><img src={m.img} alt={m.name} /></a>
        ) 

    return (
        <div>
            <div className='tg-client-apps-block'>
                <div className='tg-main-container'>
                        <div className='tg-client-hero'>
                            <h1>{props.state.hero.title}</h1>
                            <h3>{props.state.hero.subtitle}</h3>
                            <div className='tg-client-apps'>
                                {apps}
                            </div>
                        </div>
                        <img className='tg-client-heroImg' src={appImg} alt="" />

                </div>
            </div>
            <div className='tg-main-container'>
                <div className='tg-client-v-block'>
                    <h3>{props.state.instruction.title}</h3>
                    <ul>
                        {InsrtuctionItems}
                    </ul>
                </div>
                <div className='tg-client-support-block'>
                    <h3>{props.state.support.title}</h3>
                    <h6>{props.state.support.subtitle}</h6>
                    <a href={props.state.support.btnUrl}>
                        <img src={props.state.support.waIcon} alt="WhatsApp" />
                        <h6>{props.state.support.phone}</h6>
                    </a>
                    <a href={props.state.support.btnUrl}>
                        <button className='greenBtn'>{props.state.support.btnText}</button>
                    </a>
                </div>
                <div>
                    <h3>{props.state.faq}</h3>
                    <Faq />
                </div>
                <div className='tg-client-v-social-block'>
                    <h3>{props.state.social.title}</h3>
                    <div>
                        {socialItems}
                    </div>
                </div>
                <div className='tg-client-presence'>
                    <ul>
                        {presence}
                    </ul>
                </div>
                <div className='tg-client-v-business-block'>
                    <h3>{props.state.catch.title}</h3>
                    <p>{props.state.catch.subtitle}</p>
                    <NavLink to='/franchise'><button className='greenBtn'>{props.state.catch.btnText}</button></NavLink>
                    <img src={props.state.catch.img} alt="scooter" />
                </div>
            </div>
        </div>
    )
}

export default ClientPage