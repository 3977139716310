import sprayBrand from './../resources/kicksharing/spray-brand.png'
import scooter from './../resources/kicksharing/scooter-item.png'
import manageBlock from './../resources/equipment/manage-block.png'
import coins from './../resources/other/coins.png'
import verify from './../resources/kicksharing/launch-steps/verify.svg'
import access from './../resources/kicksharing/launch-steps/access.svg'
import launch from './../resources/kicksharing/launch-steps/launch.svg'
import greenWave from './../resources/green-wave.svg'
import whiteWave from './../resources/white-wave.svg'

import featureScooter from './../resources/kicksharing/features/scooter.svg'
import pin from './../resources/kicksharing/features/pin.svg'
import frendly from './../resources/kicksharing/features/access.svg'
import compact from './../resources/kicksharing/features/compact.svg'
import place from './../resources/kicksharing/features/place.svg'
import price from './../resources/kicksharing/features/price.svg'
import timer from './../resources/kicksharing/features/timer.svg'

import logotogo from './../resources/kicksharing/logo-togo.png'
import hand3d from './../resources/kicksharing/hand-3d.png'

import teltonika from './../resources/kicksharing/scooter-features/teltonika.svg'
import sim from './../resources/kicksharing/scooter-features/sim.svg'
import alert from './../resources/kicksharing/scooter-features/alert.svg'


import one from './../resources/other/nums/1.svg'
import two from './../resources/other/nums/2.svg'
import three from './../resources/other/nums/3.svg'
import four from './../resources/other/nums/4.svg'
import five from './../resources/other/nums/5.svg'
import six from './../resources/other/nums/6.svg'
import seven from './../resources/other/nums/7.svg'
import eight from './../resources/other/nums/8.svg'

import purchaise from './../resources/kicksharing/purchaise.png'
let initialState = {
    cover: {
        className: 'kicksharingCover',
        title: '20 подготовленных для проката самокатов',
        span: ' — Segway Ninebot MAX G30'
    },
    title: 'Запуск бизнеса самокатов от 1 020 000 руб.',
    priceDescription: ['100 самокатов 🚀', 'Оборот с одного самоката в месяц составляет 20 000 руб 💸', 'Окупаемость бизнеса — 4 месяца ⌛️'],
    // offer: {
    //     title: '🛴 Подготовка самоката для шеринга',
    //     subtitle: 'Телематический модуль Teltonika + установка и настройка самоката для проката',
    //     price: '7 900 руб',
    //     footnote: '* указана стоимость GPS  модуля  для самоката с полным монтажом и настройкой самоката и модуля для работы'
    // },
    scooters: [
        {
            type: '',
            name:'Ninebot Max G30',
            price:'— 51 000 руб.',
            img: scooter,
        },
        // {
        //     type: 'akb',
        //     name:'Ninebot Max Pro',
        //     price:'— 68 300 руб.',
        //     img: scooter,
        // }
    ],
    adds: {
        img: whiteWave,
        lock: {
            title: 'Электромеханический замок',
            subtitle: '3 500 руб.',
        },
        connect: {
            title: 'Подключение к мобильному приложению toGO',
            subtitle: '360 руб. за каждый активный самокат в месяц',
        },
    },
    scooterFeatures:{
        title: 'Особенности самоката',
        subtitle: 'Офицальная прошивка самоката сопрягает модуль Teltonika таким образом, что при удалении модуля самокат теряет работоспособность. Аналогов по антиугонному решению нет ни у одного производителя',
        items: [
            {
                img: teltonika,
                alt: 'teltonika',
                title: 'Телематический модуль Ninebot/Teltonika Original',
            },
            {
                img: sim,
                alt: 'sim',
                title: 'SIM-чип «M2M world»',
            },
            {
                img: alert,
                alt: 'alert',
                title: 'Оригинальная антиугонная прошивка самоката',
            },
        ],
    },
    
    taskstogo:{
        title: 'Задачи toGO',
        img: logotogo,
        div: greenWave,
        items: [
            {
                icon: 'trending_up',
                title:'Лёгкий старт',
                subtitle:'за счет узнаваемости нашего  бренда и опыта запуска'
            },
            {
                icon: 'touch_app',
                title:'Постоянное совершенствование',
                subtitle:'шеринговая платформа toGO обновляется и растет с каждым днем'
            },
            {
                icon: 'build_circle',
                title:'Настройка',
                subtitle:'поможем в настройке всех операционных процессов'
            },
            {
                icon: 'support',
                title:'Поддержка партнёров',
                subtitle:'поддержка 24/7'
            },
            {
                icon: 'credit_score',
                title:'Эквайринг',
                subtitle:''
            },
        ]
    },
    partnerstodo:
    {
        title: 'Условия для партнера',
        img: hand3d,
        items: [
            {
                icon: 'done_outline',
                title:'Вандалостойкий и всепогодный электросамокат'
            },
            {
                icon: 'done_outline',
                title:'Умная транспортная сеть, мониторинг электросамокатов и управление трафиком через облачную платформу'
            },
            {
                icon: 'done_outline',
                title:'При необходимости согласование запуска с Администрацией Вашего  города и местными властями'
            },{
                icon: 'done_outline',
                title:'Электронный замок открывается через мобильное приложение'
            },
        ]
    },
    teltonika: {
        img: manageBlock,
        divider: greenWave,
        model: 'Телематический модуль Teltonika',
        price: [
            {
                title: 'При заказе от 500 шт',
                price: '5 800 руб.',
            },
        ],
        helper: {
            title: 'Нужно меньше модулей?',
            link: 'Свяжитесь с нами в WhatsApp',
            url: 'https://wa.me/+79775388101',
        },
        priceAdd: [
            {
                title: 'Телематический модуль + сборка и настройка самокатов',
                price: '7 900 руб.',
            },
        ],
    },
    lock: {
        divider: whiteWave,
        model: 'Электромеханический замок',
        price: [
            {
                title: 'Включая монтаж на самокат и подключение',
                price: '3 500 руб.',
            },
        ],
    },
    contactUs:{
        img: purchaise,
        alt: 'purchaise',
        title:'Планируете заказ или возникли вопросы по стоимости?',
        subtitle:'Смело пишите нам в WhatsApp',
        btnText:'Связаться с toGO',
        btnURL:'https://wa.me/+79775388101'
    },
    launchSteps: 
    {
        title: 'Запуск кикшеринга в 3 этапа',
        subtitle: 'Простой запуск и постоянная поддержка партнеров toGO снимет с ваших плеч заботу о большинстве трудностей при запуске кикшеринга',
        steps: [
            {
                img: verify,
                alt: 'verify',
                title: 'Заключаем договор на поставку самокатов',
                subtitle: 'Вы платите только за готовые самокаты. Заказ от 20 самокатов, при заказе большего количества предусмотрены скидки'
            },
            {
                img: access,
                alt: 'consulting',
                title: 'Консультация по запуску',
                subtitle: 'Помощь в запуске и обучение работе в системе.'
            },
            {
                img: launch,
                alt: 'launch',
                title: 'Запуск!',
                subtitle: 'Помощь в запуске бизнеса, обучение персонала. Полный консалтинг. Мобильные приложения, платежи и техподдержку обеспечивает toGO'
            },
        ]
    },

    solution: {
        title: 'Наше решение',
        style: 'itemsList-Items',
        listType: 'numbered',
        items: [
            {
                img: one,
                alt: 1,
                text: 'Вандалостойкий и всепогодный электросамокат'

            },
            {
                img: two,
                alt: 2,
                text: 'Электромеханический замок открывается через мобильное приложение'

            },
            {
                img: three,
                alt: 3,
                text: 'Умная транспортная сеть, мониторинг электросамокатов и управление трафиком через облачную платформу'

            },
            {
                img: four,
                alt: 4,
                text: 'Приложение iOS/Android Поиск электросамокатов, расчет маршрута, быстрая регистрация и оплата'

            },
            {
                img: five,
                alt: 5,
                text: 'Hotspot — доступ к системе через собственный смартфон'

            },
        ]
    },

    ownBrand: {
        img: sprayBrand,
        alt: 'spray-art',
        title: 'У вас есть собственный бренд кикшеринга или желание его запустить?',
        subtitle: 'Ваш собственный дизайн и аппартанто-программное решение toGO',
        nav: true,
        button: 'Узнать подробности',
        url: '/own-brand',
        style: 'ownBrand'
    },

    platform: {
        img: coins,
        alt: 'toGO coins',
        title: 'Финансовые условия системы',
        subtitle: 'Решение toGO обеспечивает круглосуточный мониторинг, эквайринг и техническую поддержку',
        list: [
            {
                text: 'Ежемесячные платежи составят 360 руб. за активный самокат'
            },
        ],
    },
    
    features: {
        title: 'Преимущества решения',
        items: [
            {
                img: featureScooter,
                alt: 'scooter',
                title: 'Самокат',
                text: 'toGO одни из первых в мире применили  самокат 🛴 «Ninebot Max» в кикшеринге, и на сегодняшний день у этой серии самокатов, так и не появилось достойных конкурентов'

            },
            {
                img: pin,
                alt: 'GPS',
                title: 'GPS модуль контроля и управления самокатом',
                text: 'Наша компания официальный представитель ведущего мирового производителя GPS устройств. Модуль создан при поддержке производителя «Segway-Ninebot». Аналогов этому устройству нет.'

            },
            {
                img: frendly,
                alt: 'frendly',
                title: 'Аудитория',
                text: 'Возможность использования широкой аудиторией от 18 до 65 лет. Удобно для поездок на короткие расстояния по городу'

            },
            {
                img: place,
                alt: 'place',
                title: 'Расположение',
                text: 'У дверей, на стене, на парковке, без выделения земельного участка'

            },
            {
                img: price,
                alt: 'price',
                title: 'Стоимость',
                text: 'Доступная цена для всех категорий пользователей'
            },
            {
                img: timer,
                alt: 'time',
                title: 'Время в пути',
                text: 'В 5 раз быстрее, чем пешком и до 3 раз быстрее, чем на велосипеде, с учетом быстрой аренды, пешеходных маршрутов и комбинировании проезда на самокате с общественным транспортом'
            },
        ]
    },
}

const kicksharingReducer = (state = initialState, action) => {
    return state
}

export default kicksharingReducer