
import lightness from './../resources/other/lightness.png'
import knotstation from './../resources/stations/knot-station.png'
import scooter from './../resources/stations/scooter.png'

let initialState = {
    cover:{
        className: 'StationsCover',
        title: 'Зарядные станции ',
        span: 'для кикшеринга⚡️'
    },

    conditions: {
        img: knotstation, 
        alt: 'knot station',
        title: 'Решение по автоматизации зарядки и хранения для городов',
        subtitle: 'Мы предлагаем Вам зарядные станции от ведущего мирового производителя компании KNOT (Франция)',
        items: [
            {
              text: 'Станции на программном комплексе KNOT' 
            },
            {
                text: 'Программный комплекс toGO' 
            },
            {
                text: 'Кастомизация станций и программного комплекса для вашего бренда' 
            },
        ],
    },

    stationsPrice: {
        title: 'Стоимость станций и обслуживания',
        legend: [
            'Количество станций >',
            '1–10 станций',
            '11–50 станций',
            '51–100 станций',
        ],
        buyPrice:[
            {
                c1: 'Зарядная станция для 8 самокатов',
                c2: '€ 3 100',
                c3: '€ 2 850',
                c4: '€ 2 630',             
            },
            {
                c1: 'Зарядная станция для 4 самокатов',
                c2: '€ 2 850',
                c3: '€ 2 260',
                c4: '€ 2 150',
            }
        ],
        servicePrice: [
            {
                c1: 'Переходник для 1 самоката (если не предустановлен)',
                c2: '€ 50'
            },
            {
                c1: 'ПО, поддержка API и обслуживание соединения',
                c2: '€ 20/мес за станцию'
            },
        ]
    },
    
    contactUs: {
        img: scooter,
        alt: 'scooter toGO',
        title: 'Свяжитесь с нами, чтобы узнать условия и стоимость зарядных станций',
        subtitle: '',
        nav: false,
        button: 'Узнать условия и стоимость',
        style: 'grayNav'
    },
}

const stationsReducer = (state = initialState, action) => {
    return state
}

export default stationsReducer