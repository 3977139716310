import React from 'react'
import Partners from './Partners'
import { NavLink } from 'react-router-dom'
import ContactForm from './../ContactForm/ContactForm'

import { animateScroll as scroll } from "react-scroll";



const Franchaise = (props) => {
    scroll.scrollToTop()

    let openPopup = () => {
        props.setVisiblePopover(true)
    }

    let contacts = props.state.contactUs.links.map(m =>
        <a href={m.url} target='_blank' rel="noopener noreferrer"><img src={m.img} alt={m.name} /><h4>{m.name}</h4></a>
    )

    let features = props.state.features.map(m =>
        <div className={m.class}>
            <h3>{m.title}</h3>
            <p>{m.p}</p>
        </div>
    )

    let launch = props.state.launch.steps.map(m =>
        <div className='tg-fr-launch-card'>
            <span>{m.title}</span>
            <h4>{m.text}</h4>
        </div>
    )

    let catchFeatures = props.state.catch.features.map(m =>
        <div className={m.class}>
            <h3>{m.title}</h3>
            <p>{m.p}</p>
        </div>
    )

    let indicators = props.state.indicators.items.map(m =>
        <div>
            <span class="material-icons">{m.icon}</span>
            <h3>{m.indicator}</h3>
            <h4>{m.title}</h4>
        </div>
    )

    let apps = props.apps.apps.map(m =>
        <a href={m.url}><img src={m.imgSm} alt={m.name} /></a>
    )

    return (
        <div>
            <div className='tg-fr-fair'>
                <div className='tg-fr-fair-container'>
                    <h1>{props.state.fair.title}</h1>
                    <h2>{props.state.fair.subtitle}</h2>
                    <div className='tg-fr-fair-contact'>
                        <h3>{props.state.contactUs.title}</h3>
                        <div>
                            {contacts}
                            <a href='#' onClick={openPopup}><img src={props.state.contactUs.reverceCall.img} alt={props.state.contactUs.reverceCall.name} /><h4>{props.state.contactUs.reverceCall.name}</h4></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tg-fr-features-container'>
                    <div className='tg-fr-add-header'>
                        <h2>{props.state.mission.title}</h2>
                    </div>
                    <div className='tg-fr-img-block'>
                        <img src={props.state.mission.img} alt="photo" />
                        <div className='tg-fr-img-block-text'>
                        <div>
                            <div className='tg-fr-icon-item'>
                                <span class="material-icons">mobile_friendly</span>
                                <h4>{props.state.mission.p1}</h4>
                            </div>
                            <div className='tg-fr-apps-links'>
                                {apps}
                            </div>
                        </div>
                        <div className='tg-fr-icon-item'>
                            <span class="material-icons">manage_accounts</span>
                            <h4>{props.state.mission.p2}</h4>
                        </div>
                        <div className='tg-fr-icon-item'>
                            <span class="material-icons">cloud_done</span>
                            <h4>{props.state.mission.p3}</h4>
                        </div>
                        <div className='tg-fr-icon-item'>
                            <span class="material-icons">mobile_friendly</span>
                            <h4>{props.state.mission.p4}</h4>
                        </div>
                    </div>
                    </div>
                </div>
            <div className='tg-fr-container'>
                <div className='tg-fr-features-container'>
                    <div className='tg-fr-items-grid'>
                        {features}
                    </div>
                    <button className='tg-fr-alone-greenBtn' onClick={openPopup}>Заказать финансовый план</button>
                </div>
            </div>
            <div className='tg-fr-catch-container'>
                <h2>{props.state.contactUs.title}</h2>
                <div>
                    {contacts}
                    <a href='#' onClick={openPopup}><img src={props.state.contactUs.reverceCall.img} alt={props.state.contactUs.reverceCall.name} /><h4>{props.state.contactUs.reverceCall.name}</h4></a>
                </div>
            </div>
            <div className='tg-fr-container-indicators'>
                <h2>{props.state.indicators.title}</h2>
                <div className='tg-fr-indicators'>
                    {indicators}
                </div>
            </div>
            <div className='tg-fr-violet'>
                <div className='tg-fr-container'>
                    <div className='tg-fr-launch'>
                        <h2>{props.state.launch.title}</h2>
                        <div className='tg-fr-items-grid'>
                            {launch}
                        </div>
                        <div className='tg-fr-catchfeatures'>
                            <h2>{props.state.catch.title}</h2>
                            <div className='tg-fr-items-grid'>
                                {catchFeatures}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Partners partnersTitle={props.state.partnersTitle} />
            <div className='tg-pb-fair-gray'>
                <div className='tg-pb-container-form'>
                    <div className='tg-pb-catch'>
                        <h2>{props.state.contactUs.title}</h2>
                        <div>
                            {contacts}
                            <a href='#' onClick={openPopup}><img src={props.state.contactUs.reverceCall.img} alt={props.state.contactUs.reverceCall.name} /><h4>{props.state.contactUs.reverceCall.name}</h4></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Franchaise