import { connect } from 'react-redux';
import {setVisiblePopover} from '../../redux/contactForm'
import Press from './Press';

let mapStateTooProps = (state) => {
    return {
        state: state.press
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisiblePopover: (visible) => {
            dispatch(setVisiblePopover(visible))
    }
}}

const PressContainer = connect(mapStateTooProps ,mapDispatchToProps)(Press)

export default PressContainer