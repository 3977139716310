import { connect } from 'react-redux';
import FaqOnPage from './FaqOnPage';

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            state: state.faq.ru
        }
    } else { 
        return {
            state: state.faq.en
        }
    }
}

const FaqContainerPage = connect(mapStateTooProps)(FaqOnPage)

export default FaqContainerPage