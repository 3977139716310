let initialState = {
    ru: {
        faq: [
            {
                title: 'Что можно и что нельзя делать на Электросамокате',
                q: [
                    {
                        title: '',
                        p: [
                            'Сервис предназначен для лиц 18 лет и старше, весом не более 100 кг (включая одежду и поклажу)',
                            'Рекомендуется использовать защиту, например, шлем',
                            'Использование Электросамоката допускается исключительно в соответствии с Правилами Дорожного Движения Российской Федерации, утвержденными Постановлением Правительства РФ 23 октября 1993 года со всеми изменениями и дополнениями, действующими на дату Проката электросамоката',
                            'Не допускается использовать Электросамокат в неисправном или разряженном виде',
                            'Электросамокат не может быть использован для осуществления какой-либо предпринимательской деятельности',
                            'Не допускается использование Электросамоката вне асфальтового покрытия, а также иным способом, который может повредить Электросамокат',
                            'Не допускается использовать Электросамокат способом, опасным для жизни и здоровья третьих лиц, или иным образом, в результате которого может быть причинен вред, электросамокату, либо имуществу третьих лиц',
                            'Не допускается демонтаж, а также попытки демонтажа каких-либо деталей Электросамоката',
                            'Не допускается парковать Электросамокат, преграждая путь/проход людей или любого транспорта',
                            'Не допускается использование Электросамоката способом, сопровождающимся повышенным риском для Пользователя или Электросамоката, в том числе, но не ограничиваясь, выполнение акробатических трюков, перевозка или буксировка третьих лиц',
                            'Запрещено складывать Электросамокат',
                            'Не допускается перевозка или транспортировка Электросамоката, за исключением случаев потери работоспособности Электросамоката и невозможности его возврата своим ходом с согласия собственника Электросамоката',
                            'Не допускается, без схода с Электросамоката, преодоление препятствий, таких как бордюр, крупная или искусственная неровность, сильный перепад высот, уклон, изменение условий покрытия, жидкие поверхности, различные предметы и тд.',
                            'Не рекомендуется использование Электросамоката лицам с сердечнососудистыми заболеваниями, заболеваниями опорно-двигательного аппарата, с заболеваниями вестибулярного аппарата, а также беременным женщинам',
                        ]
                    },
                ]
            },
            {
                title: 'Аренда самоката',
                q: [
                    {
                        title: 'Как арендовать самокат и открыть замок?',
                        p: [
                            'Установите приложение toGO для iOS или Android',
                            'Зарегистрируйтесь. Для этого потребуется номер вашего телефона, адрес электронной почты и Имя',
                            'Привяжите карту оплаты',
                            'Сканируйте QR–код Электросамоката, который находится рядом с вами или введите его номер, либо найдите ближайший Электросамокат на карте в приложении',
                            'Удостоверьтесь, что звонок, тормоза, стоп-сигнал и отражатели надлежащим образом работают, рама, шины, колеса не имеет повреждений, замок электросамоката находится в рабочем состоянии. В случае, если Электросамокат содержит повреждения или неисправности, проинфоруйте toGO через приложение или по номеру телефона поддержки Пользователей',
                            'Выберите тариф – нажми «Все ОК, поехали», дальше замок автоматически откроется на 10 секунд или придёт сообщение с кодом, если Электросамокат снабжён кодовым замком',
                            'Если замок заедает, подвигайте трос сначала внутрь, потом наружу',
                            'После начала аренды, вставьте трос назад в гнездо замка',
                            'Для начала движения оттолкнитесь, чтобы набрать скорость, затем нажмите на клавишу рядом с правым большим пальцем, чтобы ускориться',
                            'Чтобы уменьшить скорость, нажимайте на тормоз рядом в левым большим пальцем (для ES1 и ES2), или механический тормоз, аналогичный велосипедному (для MAX), или обычным ножным тормозом у заднего колеса',
                            'Для аренды нескольких самокатов с одного аккаунта нажмите кнопку «забронировать еще» в окне карты и повторите действия, описанные выше',
                            'Не нажимайте одновременно рычаги газа и тормоза',
                            'Не держите нажатыми рычаги газа или тормоза до начала аренды'
                        ]
                    },
                    {
                        title: 'Можно ли арендовать несколько самокатов на один аккаунт?',
                        p: [
                            'Да, это возможно, при этом ответственность, за все арендованные Электросамокаты несёт тот Пользователь, на чей аккаунт они были арендованы, даже если на самокате катался другой человек.Все правила пользования распространяются на каждый арендованный Электросамокат'
                        ]
                    },
                    {
                        title: 'Как завершить аренду и закрыть замок?',
                        p: [
                            'Завершить аренду можно в разрешенной зоне, на карте она обозначена зеленым цветом',
                            'Войдя в зону, найдите свободное пространство, где Электросамокат не будет мешать другим участникам дорожного движения',
                            'Нажмите кнопку «Завершить аренду», замок откроется автоматически или введите код, если Электросамокат снабжён кодовым замком',
                            'Зафиксируйте трос замка в какой-либо стационарной конструкции (например, велосипедной парковке или ограде), запрещается крепить Электросамокат к дорожным знакам, мачтам освещения, водосточным трубам',
                            'Сделайте необходимые фото'
                        ]
                    },
                    {
                        title: 'Пауза',
                        p: [
                            'Вы можете приостановить поездку, чтобы другой человек не мог использовать Ваш самокат',
                            'Обратите внимание, что с вас будет взиматься поминутная оплата, согласно вашему тарифу (не как за саму поездку) пока ваша поездка приостановлена',
                            'Чтобы прекратить списание денег, Вам нужно нажать «Завершить аренду». Имейте в виду, что это сделает доступным самокат для использования другими',
                            'Пожалуйста, не забудьте нажать «Завершить поездку», когда достигните пункта назначения! Деньги будут списываться с карты, пока вы не Завершите аренду'
                        ]
                    },
                ]
            },
            {
                title: 'Проблемы с самокатом',
                q: [
                    {
                        title: 'Повреждения',
                        p: [
                            'В случае мелких повреждений (царапина, загрязнения и прочее), которые не влияют на безопасность вождения – обозначьте их в приложении и сфотографируйте, после чего можете начать аренду',
                            'В случае серьезных повреждений (деформация колес или рамы самоката, не работающая ручка газа или тормоза и прочее) обозначьте их в приложении, сфотографируйте и не начинайте аренду. Используйте, пожалуйста, другой Электросамокат',
                            'Пожалуйста, сообщите в службу поддержки номер самоката — он написан на стойке руля',
                        ]
                    },
                    {
                        title: 'Не позволяет завершить аренду',
                        p: [
                            'Пожалуйста, проверьте, что самокат находится в разрешенной для завершения аренды зоне, в приложении она обозначена зеленым цветом'
                        ]
                    },
                    {
                        title: 'Не открывается замок',
                        p: [
                            'Пожалуйста, убедитесь, что перед вами Электросамокат toGO, его номер (указан на стойке руля) совпадает с номером в приложении. Если Электросамокат снабжён кодовым замком, проверьте правильность введённого кода'
                        ]
                    },
                    {
                        title: 'Не включается самокат',
                        p: [
                            'Пожалуйста, проверьте, что у вас выбран тариф и самокат переведён в аренду',
                            'Если ни одно из вышеперечисленных предложений не помогло решить проблему, сообщите о проблеме в службу поддержки, контакты которой указаны в приложении'
                        ]
                    }
                ]
            },
            {
                title: 'Тарифы и проблемы с оплатой',
                q: [
                    {
                        title: 'Стоимость аренды',
                        p: [
                            'Актуальная информация о тарифах становится в приложении перед началом аренды. Тарифы могут различаться в разных регионах. Нажимая кнопку «Начать аренду» пользователь соглашается с тарифом',
                            'При начале пользования Сервисом toGO на карте резервируется сумма эквивалентная часу поездки на минутном Тарифе если средств на карте Пользователя, то поездку невозможно начать',
                        ]
                    },
                    {
                        title: 'Замок закрыт, но деньги продолжают начисляться',
                        p: [
                            'Аренда электросамоката не завершена. Завершите аренду'
                        ]
                    },
                    {
                        title: 'Почему было списано 100 рублей, хотя поезка была кратковременной?',
                        p: [
                            'Если сумма транзакции, по результатам пользования Сервисом, будет меньше размера Минимального платежа, то будет списан Минимальный платеж, согласно тарифу, при этом разница будет зачисляться на счет Пользователя, который можно будет потратить на последующие поездки'
                        ]
                    },
                ]
            },
            {
                title: 'Банковские карты',
                q: [
                    {
                        title: 'Как удалить карту?',
                        p: [
                            'Откройте приложение, в верхнем левом углу нажмите значок меню',
                            'Далее нажмите «Кошелек»',
                            'Выберите карту и нажмите на крестик в правом верхнем углу изображения вашей карты'
                        ]
                    },
                    {
                        title: 'Нельзя осуществить платёж с карты',
                        p: [
                            'Не все банковские карты могут быть использованы для совершения платежей, о чём становится известно после поппытки совершить платёж. У карты должна быть подключена 3ds-аутентификация',
                            'Также проверьте срок действия карты, он не должен заканчиваться в текущем месяце',
                            'Если ничего не помогло, пожалуйста, попробуйте другую карту'
                        ]
                    },
                ]
            },
            {
                title: 'Как связаться со службой поддержки?',
                q: [
                    {
                        title: '',
                        p: [
                            'Контакты службы поддержки указаны в приложении, для каждого региона они свои',
                            'Откройте приложение, в верхнем левом углу нажмите значок меню (три полосы), меню “Поддержка”, выберите удобный доступный и удобный для вас способ связи'
                        ]
                    }
                ]
            },
            {
                title: 'Что делать, если я выехал из зоны разрешенной для поездок?',
                q: [
                    {
                        title: '',
                        p: [
                            'Приложение и самокат начнут сигнализировать о пересечении разрешенной зоны катания (обозначена зелёным цветом). Вернитесь обратно в зону разрещённого катания и продолжайте поездку, учитывая размеры и форму зоны',
                            'Если вы проигнорируете эти сигналы, то это будет расценено, как попытка хищения Электросамоката, его двигатель будет заблокирован, в случае с Ninebot MAX, возможна блокировка колеса. Будут вызваны сотрудники охранного предприятия или  полиция. Поднимется вопрос о штрафе'
                        ]
                    }
                ]
            },
            {
                title: 'Как удалить аккаунт?',
                q: [
                    {
                        title: '',
                        p: [
                            'Напишите письмо на адрес почты службы поддержки, указав номер телефона, на который зарегистрирован аккаунт'
                        ]
                    }
                ]
            },
        ]
    },
    en: {
        faq: [
            {
                title: "What you can and can't to do on scooter",
                q: [
                    {
                        title: '',
                        p: [
                            'The service is intended for 18 years old and older, weighing no more than 100 kg (including clothing and luggage)',
                            'We recommend using protection, such as a helmet.',
                            'Use of the Scooter is allowed only in accordance with the Road Traffic Rules of the Russian Federation, approved by the Decree of the Government of the Russian Federation on October 23, 1993 with all changes and additions in force on the date of the Electric Scooter Rental',
                            'It is not allowed to use the scooter in defective or discharged state.',
                            'Scooter cannot be used for any business activity',
                            'It is not allowed to use the Scooter outside the asphalt surface, as well as in any other way that could damage the Electric Scooter',
                            'It is not allowed to use scooter in a way that is dangerous to the life and health of third parties, or in any other way, as a result of which harm may be caused to the electric scooter, or property of third parties',
                            'Dismantling, as well as attempts to dismantle any parts of the scooter, are not allowed',
                            'It is not allowed to park the Electric scooter, blocking the path of people or transport',
                            'Not allowed to use the scooter in a way that involves an increased risk for the User or the Scooter, including, but not limited to, performing acrobatic stunts, transporting or towing third parties',
                            'Forbidden to fold the electric scooter',
                            'Transportation the scooter is not allowed, except in cases of loss of performance of the Electric Scooter and the impossibility of returning it on its own with the consent of the owner of the scooter',
                            'Not allowed, without getting off the scooter, overcoming obstacles such as a curb, large or artificial unevenness, strong elevation difference, slope, changing conditions of coverage, liquid surfaces, various objects, etc.',
                            'Use of the Electric Scooter is not recommended for people with cardiovascular diseases, diseases of the musculoskeletal system, diseases of the vestibular system, as well as for pregnant women',
                        ]
                    },
                ]
            },
            {
                title: 'Scooter rental',
                q: [
                    {
                        title: 'How to rent scooter & open the lock?',
                        p: [
                            'Install toGO app for iOS or Android',
                            'Register using your phone number, email address and name',
                            'Add the payment card',
                            'Scan the QR on the scooter or enter its number. Also you can find the nearest electric scooter on the map in app',
                            'Make sure that the bell, brakes, brake light and reflectors are working properly, the frame, tires, wheels are not damaged, the electric scooter lock is working properly. If the scooter is damaged or malfunctioning, please report it to toGO via the app or by calling the customer support phone number',
                            "Select rate - click Everything is OK, then the lock will automatically open for 10 seconds or a message with a code will be sent if the scooter is equipped with a combination lock",
                            'If the lock sticks, move the cable first in, then out',
                            'After the rent will start, insert the cable back into the lock slot',
                            'To start moving, push off to pick up speed, then press the key near your right thumb to accelerate',
                            'To reduce speed, apply the brake next to your left thumb (for ES1 and ES2), or a mechanical brake like a bicycle brake (for MAX), or a conventional foot brake at the rear wheel',
                            'To rent several scooters from one account, click the "book more" button in the map window and repeat the steps described above',
                            'Do not press the throttle and brake levers at the same time',
                            'Do not hold the throttle or brake levers until the rental starts'
                        ]
                    },
                    {
                        title: 'Can I rent multiple scooters for one account?',
                        p: [
                            'Yes, it is possible, at the same time responsibility for all rented Electric scooters is borne by the User on whose account they were rented, even if another person rode the scooter. All terms of use apply to each rented Electric scooter'
                        ]
                    },
                    {
                        title: 'How to end the lease and close the lock?',
                        p: [
                            'You can end the lease in the permitted area, it is marked in green on the map',
                            'After entering the area, find a clear space where the scooter will not interfere with other road users',
                            'Click the "Finish rent" button, the lock will open automatically or enter the code if the scooter is equipped with a combination lock',
                            'Secure the cable lock in any stationary structure (for example, a bicycle parking lot or fence), do not attach the scooter to road signs, lighting masts, downpipes',
                            'Take the necessary photos'
                        ]
                    },
                    {
                        title: 'Pause',
                        p: [
                            'You can pause your ride to prevent another person from using your scooter',
                            'Please note that you will be charged per minute according to your rate (not as for the trip itself) while your trip is suspended',
                            'To stop debiting money, you need to click “End rent”. Be aware that this will make the scooter available for others to use.',
                            'Please remember to click on End Trip when you reach your destination! The money will be debited from the card until you complete the lease'
                        ]
                    },
                ]
            },
            {
                title: 'Scooter problems',
                q: [
                    {
                        title: "Scooter's damage",
                        p: [
                            'In case of minor damages (scratches, dirt, etc.) that do not affect driving safety - mark them in the application and take a photo, after which you can start renting',
                            'In case of serious damage (deformation of the wheels or frame of the scooter, inoperative throttle or brakes, etc.), indicate them in the application, take a photo and do not start renting. Please use another Electric scooter',
                            'Tell the support service the number of the scooter - it is written on the steering wheel.',
                        ]
                    },
                    {
                        title: "Doesn't allow the lease to be completed",
                        p: [
                            'Please check that the scooter is in the area allowed for the completion of the rental, in the application it is indicated in blue'
                        ]
                    },
                    {
                        title: 'The lock does not open',
                        p: [
                            'Up-to-date information on tariffs becomes in the application before the start of the rental. Prices may vary in different regions. By pressing the "Start rental" button, the user agrees to the tariff'
                        ]
                    },
                    {
                        title: 'The scooter does not turn on',
                        p: [
                            'Please check that you have selected a rate and the scooter is rented',
                            'If none of the above suggestions helped to solve the problem, please report the problem to the support service, whose contacts are listed in the application'
                        ]
                    }
                ]
            },
            {
                title: 'The scooter does not turn on',
                q: [
                    {
                        title: 'Rent price',
                        p: [
                            'Up-to-date information on tariffs becomes in the application before the start of the rental. Prices may vary in different regions. By pressing the "Start rental" button, the user agrees to the tariff',
                            "When starting to use the toGO Service, an amount equivalent to an hour of travel on the minute Tariff is reserved on the card; if there is not enough funds on the User's card, then the trip cannot be started",
                        ]
                    },
                    {
                        title: 'The lock is closed, but the money continues to accrue',
                        p: [
                            'The lock is closed, but the money continues to be debited'
                        ]
                    },
                    {
                        title: 'Why was 100 rubles written off, although the trip was short-lived?',
                        p: [
                            "If the transaction amount, based on the results of using the Service, is less than the size of the Minimum Payment, then the Minimum Payment will be debited, according to the tariff, and the difference will be credited to the User's account, which can be spent on subsequent trips"
                        ]
                    },
                ]
            },
            {
                title: 'Bank cards',
                q: [
                    {
                        title: 'How do I remove a card?',
                        p: [
                            'Open the app, in the upper left corner, click the menu icon',
                            'Then click "Wallet"',
                            'Select a card and click on the cross in the upper right corner of the image of your card'
                        ]
                    },
                    {
                        title: 'Card payment cannot be made',
                        p: [
                            'Not all bank cards can be used to make payments, which becomes known after an attempt to make a payment. The card must have 3ds authentication enabled',
                            'Also check the card expiration date, it should not expire in the current month',
                            'If all else fails, please try a different card'
                        ]
                    },
                ]
            },
            {
                title: 'How can I contact support?',
                q: [
                    {
                        title: '',
                        p: [
                            'Support contacts are listed in the application, they are different for each region',
                            'Open the application, in the upper left corner, click the menu icon, the "Support" menu, choose a convenient, accessible and convenient way of communication'
                        ]
                    }
                ]
            },
            {
                title: 'What if I am outside a permitted travel area?',
                q: [
                    {
                        title: '',
                        p: [
                            'The app and the scooter will start signaling about crossing the permitted ski area (marked in green). Return back to the permitted ski area and continue riding, taking into account the size and shape of the area',
                            'If you ignore these signals, it will be regarded as an attempt to steal the Electric scooter, its engine will be blocked, in the case of the Ninebot MAX, the wheel may be blocked. Security officers or the police will be called. The question of the fine will be raised'
                        ]
                    }
                ]
            },
            {
                title: 'How to delete an account?',
                q: [
                    {
                        title: '',
                        p: [
                            'Write a letter to the email address of the support service, indicating the phone number to which the account is registered'
                        ]
                    }
                ]
            },
        ]
    }
}

const faqReducer = (state = initialState, action) => {
    return state
}

export default faqReducer