import { connect } from 'react-redux'
import MainMenu from './MainMenu'
import { setVisible } from '../../redux/mainMenu'

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            menu: state.mainMenu.ru,
            apps: state.apps,
            visible: state.mainMenu.visible
        }
    } else {
        return {
            menu: state.mainMenu.en,
            apps: state.apps,
            visible: state.mainMenu.visible
        }
    }
}
let mapDispatchToProps = (dispatch) => {
    return {
        setVisible: (visible) => {
            dispatch(setVisible(visible))
        }
    }
}

const MainMenuContainer = connect(mapStateTooProps, mapDispatchToProps)(MainMenu)

export default MainMenuContainer