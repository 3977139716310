import telegram from './../resources/telegram.svg'
import wa from './../resources/wa-logo.svg'
import missionimg from './../resources/franchaise/photo/cross-the-street.jpg'
import mail from './../resources/mail-icon.svg'



let initialState = {
    ru: {
        fair: {
            title: "Первая франшиза кикшеринга",
            subtitle: "с 2018 года",
        },
        contactUs: {
            title: "Консультация по запуску сервиса в вашем городе:",
            btn: 'Обратная связь',
            links: [
                {
                    name: 'Telegram',
                    url: 'https://t.me/togokicksharing',
                    img: telegram,
                },
                {
                    name: 'WhatsApp',
                    url: 'https://api.whatsapp.com/send/?phone=79775388101',
                    img: wa,
                },
            ],
            reverceCall: {
                name: 'Обратная связь',
                img: mail,
            }
        },
        mission: {
            img: missionimg,
            title: 'Что получает партнер toGO',
            p1: 'Передовые мобильные приложения на IOS и Android',
            p2: 'Мобильное приложение для техников на IOS и на Android',
            p3: 'CRM-систему и облачную систему мониторинга и контроля парка самокатов',
            p4: 'Финансовый план'

        },
        indicators: {
            title: 'Основные показатели',
            items: [
                {
                    title: 'Средний чек поездки',
                    indicator: '150-200 руб.',
                    icon: 'receipt_long',
                },{
                    title: 'Поездки за сутки',
                    indicator: '2-3',
                    icon: 'flag',
                },{
                    title: 'Выручка с одного самоката в день',
                    indicator: '300-600 руб.',
                    icon: 'payments',
                },{
                    title: 'Чистая прибыль с одного самоката',
                    indicator: '10 000 руб.',
                    icon: 'account_balance_wallet',
                }
            ]
        },
        features: [
            {
                title: 'Специальное предложение',
                p: '50 оборудованных GPS модулями самокатов Ninebot Max G30 за 1.900.000 ₽',
                class: 'tg-fr-featureCard-three'
            },
            {
                title: 'Технологии',
                p: 'Инновационная платформа для шеринга и бизнеса «toGO Tech»',
                class: 'tg-fr-featureCard-one'
            },
            {
                title: 'Опыт',
                p: 'Первая компания, запустившая франшизу кикшеринга',
                class: 'tg-fr-featureCard-two'
            },
            {
                title: 'Скорость',
                p: 'Запуск сервиса в Вашем городе за 10 дней',
                class: 'tg-fr-featureCard-four'
            },
            {
                title: 'Бизнес',
                p: 'Окупаемость ~6 месяцев',
                class: 'tg-fr-featureCard-five'
            },
            {
                title: 'Нет паушального взноса и платежей',
                p: 'Никаких взносов для запуска сервиса',
                class: 'tg-fr-featureCard-six'
            },
            {
                title: 'Персонал',
                p: 'На 50 самокатов требуется ~ 2 сотрудника',
                class: 'tg-fr-featureCard-seven'
            },
            {
                title: 'Наука',
                p: 'Опытная команда специалистов и разработчиков',
                class: 'tg-fr-featureCard-eight'
            },
            {
                title: 'Помощь 24/7',
                p: 'Консалтинг. Поддержка. Консультации на всех этапах запуска',
                class: 'tg-fr-featureCard-nine'
            },
            {
                title: 'Подключаем к работе  всю линейку самокатов',
                p: 'Segway-Ninebot Max g30, Max Plus, Max Pro, X и OKAI ES 400, ES600',
                class: 'tg-fr-featureCard-ten'
            },
            {
                title: 'Поддержка самокатов других компаний кикшеринга',
                p: 'Easy moving: Если вы работаете под другим брендом и программой, но хотите перейти в toGO: Вам ничего не придется делать, мы переподключим  Ваши самокаты онлайн с любым оборудованием и программой.',
                class: 'tg-fr-featureCard-eleven'
            },
            {
                title: 'Мультиязычность и банк',
                p: 'Платформа «toGO Tech» поддерживает все языки и платежные системы',
                class: 'tg-fr-featureCard-twelve'
            },
            {
                title: 'Собственный бренд / White Label',
                p: 'Возможность запуска сервиса   с собственным  брендом, маркой, наименованием',
                class: 'tg-fr-featureCard-thirteen'
            },
            {
                title: 'Инновации',
                p: 'Возможность сдавать в аренду не только электросамокаты...',
                class: 'tg-fr-featureCard-fourteen'
            },
        ],
        launch: {
            title: 'Запуск кикшеринга в вашем городе',
            steps: [
                {
                    title: '1 этап',
                    text: 'Заключение договора на работу с программным комплексом toGO'
                },
                {
                    title: '2 этап',
                    text: 'Покупка самокатов у любого поставщика на Ваш выбор'
                }, {
                    title: '3 этап',
                    text: 'Найм персонала, обучение и запуск сервиса'
                },
            ]
        },
        catch: {
            title: 'Подключаясь к toGO вы получаете',
            features: [
                {
                    title: 'Независимость',
                    p: 'Вы сами выбираете тарифы и зоны',
                    class: 'tg-fr-altCard-one'
                },
                {
                    title: 'Экономию',
                    p: 'Самостоятельно покупаете технику без наценки',
                    class: 'tg-fr-altCard-two'
                },
                {
                    title: 'Надежность',
                    p: 'Компания работает с 2018 года',
                    class: 'tg-fr-altCard-three'
                },
                {
                    title: 'Опыт',
                    p: 'Мы первый сервис кикшеринга, запустивший франшизу',
                    class: 'tg-fr-altCard-four'
                },
                {
                    title: 'Низкую комиссию  эквайринга',
                    p: 'Комиссия платежной системы 2.5%',
                    class: 'tg-fr-altCard-five'
                },
                {
                    title: 'Отсутствие лишних трат',
                    p: 'Вы оплачиваете, только покупку самокатов',
                    class: 'tg-fr-altCard-six'
                },
                {
                    title: 'Безопасность',
                    p: 'Все самокаты имеют техническую защиту от кражи',
                    class: 'tg-fr-altCard-seven'
                },
                {
                    title: 'Инновации',
                    p: 'Платформа “toGO Tech” постоянно модернизируется и совершенствуется',
                    class: 'tg-fr-altCard-eight'
                },
                {
                    title: 'Поддержка',
                    p: 'Круглосуточный колл-центр поддержки клиентов',
                    class: 'tg-fr-featureCard-nine'
                },
                {
                    title: 'Новых клиентов',
                    p: 'Партнер получает доступ к базе клиентов',
                    class: 'tg-fr-altCard-ten'
                },
                {
                    title: 'Бонусы',
                    p: 'Реферальная и бонусная программа для пользователей',
                    class: 'tg-fr-altCard-eleven'
                },
                {
                    title: 'Обучение',
                    p: 'Обучение сотрудников для запуска сервиса',
                    class: 'tg-fr-altCard-twelve'
                },
                {
                    title: 'Страхование',
                    p: 'Возможность застраховать имущество в ведущих страховых компаниях',
                    class: 'tg-fr-altCard-thirteen'
                },
                {
                    title: 'Лизинг',
                    p: 'Возможность приобрести самокаты у компании партнера в лизинг',
                    class: 'tg-fr-altCard-fourteen'
                },
                {
                    title: 'Платежи, онлайн касса',
                    p: 'Все средства за поездки сразу зачисляются партнеру на его личный счет',
                    class: 'tg-fr-altCard-fifteen'
                }
            ]
        },
        partnersTitle: 'Партнеры toGO'
    },
    en: {
        fair: {
            title: "First kicksharing franchise",
            subtitle: "since 2018",
        },
        contactUs: {
            title: "Service launch consultation",
            links: [
                {
                    name: 'Telegram',
                    url: 'https://t.me/togokicksharing',
                    img: telegram,
                },
                {
                    name: 'WhatsApp',
                    url: 'https://api.whatsapp.com/send/?phone=79775388101',
                    img: wa,
                },
            ],
            reverceCall: {
                name: 'Feedback',
                img: mail,
            }
        },
        mission: {
            img: missionimg,
            title: 'Что получает партнер toGO',
            p1: 'Advanced mobile apps for IOS and Android',
            p2: 'Mobile app for technicians on IOS and Android',
            p3: 'CRM system and cloud scooter fleet monitoring and control system',
            p4: 'Financial plan'

        },
        indicators: {
            title: 'Basic indicators',
            items: [
                {
                    title: 'Average check of the trip',
                    indicator: '2-3 USD',
                    icon: 'receipt_long',
                },{
                    title: 'Rides per day',
                    indicator: '2-3',
                    icon: 'flag',
                },{
                    title: 'Revenue from one scooter per day',
                    indicator: '6-8 USD',
                    icon: 'payments',
                },{
                    title: 'Net profit from one scooter',
                    indicator: '140 USD',
                    icon: 'account_balance_wallet',
                }
            ]
        },
        features: [
            {
                title: 'Special offer',
                p: '50 ready-made scooters Ninebot Max G30 by 25 000 USD',
                class: 'tg-fr-featureCard-three'
            },
            {
                title: 'Technologies',
                p: 'Innovative platform for sharing and business toGO Tech',
                class: 'tg-fr-featureCard-one'
            },
            {
                title: 'Experience',
                p: 'First company to launch a kicksharing franchise',
                class: 'tg-fr-featureCard-two'
            },
            {
                title: 'Скорость',
                p: 'Service launch in your city in 10 days',
                class: 'tg-fr-featureCard-four'
            },
            {
                title: 'Business',
                p: 'Payback in 6 months',
                class: 'tg-fr-featureCard-five'
            },
            {
                title: 'Without extra spending',
                p: 'No fees to start the service',
                class: 'tg-fr-featureCard-six'
            },
            {
                title: 'Staff',
                p: '2 employees for 50 scooters',
                class: 'tg-fr-featureCard-seven'
            },
            {
                title: 'Science',
                p: 'Experienced team of technicians and developers',
                class: 'tg-fr-featureCard-eight'
            },
            {
                title: 'Support 24/7',
                p: 'Consulting. Support. Consultations at all stages of launch',
                class: 'tg-fr-featureCard-nine'
            },
            {
                title: 'Scooters',
                p: 'Ninebot Max G30 without replaceable battery and scooters with replaceable battery - Ninebot Pro, Ninebot Plus and Okai ES400',
                class: 'tg-fr-featureCard-ten'
            },
            {
                title: 'Maintenance',
                p: 'We connect scooters to our system from other services with any GPS module',
                class: 'tg-fr-featureCard-eleven'
            },
            {
                title: 'Multilingual and billing',
                p: 'The toGO Tech platform supports all languages and payment systems',
                class: 'tg-fr-featureCard-twelve'
            },
            {
                title: 'Your own brand',
                p: 'The ability to launch the service under your own trade name',
                class: 'tg-fr-featureCard-thirteen'
            },
            {
                title: 'Innovations',
                p: 'The ability to rent not only electric scooters ...',
                class: 'tg-fr-featureCard-fourteen'
            },
        ],
        launch: {
            title: 'Launching kicksharing in your city',
            steps: [
                {
                    title: 'Step 1',
                    text: 'Conclusion of a contract for work with the toGO software package'
                },
                {
                    title: 'Step 2',
                    text: 'Buying scooters from any supplier of your choice'
                }, {
                    title: 'Step 3',
                    text: 'Hiring staff, training and launching the service'
                },
            ]
        },
        catch: {
            title: 'By connecting toGO you will get',
            features: [
                {
                    title: 'Independence',
                    p: 'You choose the rates and zones yourself',
                    class: 'tg-fr-altCard-one'
                },
                {
                    title: 'Savings',
                    p: 'Buy equipment yourself without extra charge',
                    class: 'tg-fr-altCard-two'
                },
                {
                    title: 'Reliability',
                    p: 'The company has been operating since 2018',
                    class: 'tg-fr-altCard-three'
                },
                {
                    title: 'Experience',
                    p: 'We are the first kicksharing service to launch a franchise',
                    class: 'tg-fr-altCard-four'
                },
                {
                    title: 'Low acquiring commission',
                    p: 'Payment system commission 2.5%',
                    class: 'tg-fr-altCard-five'
                },
                {
                    title: 'No unnecessary spending',
                    p: 'You pay only for the purchase of scooters',
                    class: 'tg-fr-altCard-six'
                },
                {
                    title: 'Safety',
                    p: 'All scooters have technical protection against theft',
                    class: 'tg-fr-altCard-seven'
                },
                {
                    title: 'Innovations',
                    p: 'The toGO Tech platform is constantly being modernized and improved',
                    class: 'tg-fr-altCard-eight'
                },
                {
                    title: 'Support',
                    p: '24/7 customer support call center',
                    class: 'tg-fr-featureCard-nine'
                },
                {
                    title: 'New clients',
                    p: 'The partner gets access to the customer base',
                    class: 'tg-fr-altCard-ten'
                },
                {
                    title: 'Bonuses',
                    p: 'Referral and bonus program for users',
                    class: 'tg-fr-altCard-eleven'
                },
                {
                    title: 'Learning',
                    p: 'Training employees to launch the service',
                    class: 'tg-fr-altCard-twelve'
                },
                {
                    title: 'Insurance',
                    p: 'Opportunity to insure property with leading insurance companies',
                    class: 'tg-fr-altCard-thirteen'
                },
                {
                    title: 'Leasing',
                    p: 'Opportunity to purchase scooters from a partner company on lease',
                    class: 'tg-fr-altCard-fourteen'
                },
                {
                    title: 'Payments, online checkout',
                    p: 'All funds for travel are immediately credited to the partner to his personal account',
                    class: 'tg-fr-altCard-fifteen'
                }
            ]
        },
        partnersTitle: 'Партнеры toGO'
    }
}

const franchaiseReducer = (state = initialState, action) => {
    return state
}

export default franchaiseReducer