import waLogo from './../resources/wa-logo.svg'

let initialState = {
    ru: {
        p: 'Техподдержка',
        pAlt: 'Написать в поддержку',
        waLogo: waLogo,
        url: 'https://wa.me/79381587755'
    },
    en: {
        p: 'Support',
        pAlt: 'Contact our support',
        waLogo: waLogo,
        url: 'https://wa.me/79381587755'
    }
}

const supportReducer = (state = initialState, action) => {
    return state
}

export default supportReducer