import React from 'react'
import { NavLink } from 'react-router-dom'

const CatchBlockWithBg = (props) => {

    let openPopup = () => {
        props.setVisiblePopover(true)
    }

    return (
        <div className={props.state.style}>
            <h4>{props.state.title}</h4>
            <h5>{props.state.subtitle}</h5>
            {props.state.nav
                ? <NavLink to={props.state.url} className='greenBtn'>{props.state.button}</NavLink>
                : <button className='greenBtn' onClick={openPopup}>{props.state.button}</button>
            }
        </div>
    )
}

export default CatchBlockWithBg