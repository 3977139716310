import * as Yup from "yup";

// const phoneRegExp = /^(\+[0-1000])?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
const BasicFormSchema = Yup.object().shape({
    name: Yup.string()
        //минимальная длина - 2 символа
        .min(4, "Введите не менее 4 символов")
        //максимальная длина - 20 символов
        .max(200, "Поле может содержать не более 200 символов")
        .required("Обязательное поле"),
    email: Yup.string()
        //Проверяем, корректный ли адрес.
        //Если нет, то выводится сообщение в скобках
        .email("Email указан некорректно. Проверьте правильность ввода")
        //не сабмитим, если поле не заполнено
        .required("Обязательное поле"),
    phone: Yup.number().typeError("Введите только числа, без скобок и дефисов. Например, +7 999 888 77 66")
        // .matches(phoneRegExp, 'Формат номера +7 (111) 222-33-44. Можно без скобок и дефисов')
        .required("Обязательное поле"),
    message: Yup.string()
        .max(500, "Поле может содержать не более 500 символов")
});
export default BasicFormSchema;