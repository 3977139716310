import { connect } from 'react-redux'
import { setVisible } from '../../redux/mainMenu'
import Header from './Header'

let mapStateTooProps = (state) => {
    if (state.lang.currentLanguage === 'Ru') {
        return {
            menu: state.mainMenu.ru,
            social: state.client.ru.social,
            apps: state.apps,
            support: state.support.ru
        }
    } else { 
        return {
            menu: state.mainMenu.en,
            social: state.client.en.social,
            apps: state.apps,
            support: state.support.en
        }
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setVisible: (visible) => {
            dispatch(setVisible(visible))
        }
    }
}

const HeaderContainer = connect(mapStateTooProps, mapDispatchToProps)(Header)

export default HeaderContainer