import telegram from './../resources/telegram.svg'
import wa from './../resources/wa-logo.svg'
import mail from './../resources/mail-icon.svg'
import missionimg from './../resources/franchaise/photo/cross-the-street.jpg'
import pb1 from './../resources/powerbanks/pb-1.png'
import pb2 from './../resources/powerbanks/pb-2.png'
import pb4 from './../resources/powerbanks/pb-4.png'
import pb5 from './../resources/powerbanks/pb-5.png'


let initialState = {
    ru: {
        fair: {
            title: "Франшиза toGO заряд",
            subtitle: "Шеринг портативных зарядных устройств power bank",
        },
        contactUs: {
            title: "Запусти бизнес в своём городе с окупаемостью 6 месяцев, который не требует  полного участия инвестора  - все автоматизировано!",
            btn: 'Обратная связь',
            links: [
                {
                    name: 'Telegram',
                    url: 'https://t.me/togokicksharing',
                    img: telegram,
                },
                {
                    name: 'WhatsApp',
                    url: 'https://api.whatsapp.com/send/?phone=79775388101',
                    img: wa,
                },
            ],
            reverceCall: {
                name: 'Обратная связь',
                img: mail,
            }
        },
        partnersProgram: {
            img: pb1,
            title: 'Партнерская программа toGO заряд',
            p1: 'Стремительно набирающий обороты PowerBank sharing уже с начала своего появления имеет огромный спрос. Современный пользователь ценит мобильность, он всегда в движении и предпочитает заряжать телефон в пути, а не терять время стоя у розетки. К слову, о потери времени, мы создали доступный и понятный веб-сервис для легкой и быстрой регистрации',
            p2: 'Общество давно привыкло платить за удобство и качество жизни, поэтому мы так смело выходим на рынок с нашим решением, привлекаем партнеров и предлагаем зарабатывать вместе',

        },
        analyze: {
            title: 'Анализ рынка шеринга портативных аккумуляторов',
            subtitle: 'Анализ показал, что 10.2% опрошенных хотят воспользоваться и готовы платить за сервис, предоставляемый toGO',
            items: [
                {
                    quontity: '68%',
                    p: 'сталкиваются с проблемой нехватки аккумулятора ежедневно'
                },{
                    quontity: '21,8%',
                    p: 'готовы воспользоваться сервисом и считают его потенциально удобным'
                },{
                    quontity: '10,2%',
                    p: 'готовы платить указанную сумму за пользование сервисом'
                },
            ]
        },
        statistic: {
            title: 'Статистическая справка',
            p1: 'Эксперты McKinsey прогнозируют, что к 2025 году половина мировой экономики будет задействована в совместных моделях потребления.',
            p2: 'Потенциал роста объёма рынка шеринговой экономики, по оценке PwC, значительно выше текущего: отрасль способна вырасти до $335 млрд к 2025 году.',
            items: [
                {
                    quontity: '+85%',
                    p: 'Аренда вещей'
                },{
                    quontity: '-48%',
                    p: 'Шеринг жилья'
                },{
                    quontity: '+48%',
                    p: 'C2C продажи'
                },{
                    quontity: '-36%',
                    p: 'Карпулинг'
                },{
                    quontity: '+35%',
                    p: 'Индивидуальная собственность'
                },{
                    quontity: '-28%',
                    p: 'Офисшеринг'
                },

            ]
        },
        finance: {
            title: 'Финансовая обоснованность',
            items: [
                {
                    icon: 'tune',
                    title: 'Тарифы',
                    ul: [
                        'Час — 49 руб', 'Сутки — 99 руб', 'Средний чек — 74 руб', 'Среднее количество арендованных power bank на одной станции в день — 4'
                    ]
                },{
                    icon: 'payments',
                    title: 'Выручка',
                    ul: [
                        'С одной станции — 296 руб/день', 'С одной станции — 8 800 руб/мес', 'От всей сети — 182 600 руб'
                    ]
                },{
                    icon: 'shopping_bag',
                    title: 'Стоимость',
                    ul: [
                        'Одна станция на 6 слотов — 27 000 руб'
                    ]
                },
            ]
        },
        solution: {
            title: 'Что мы предлагаем партнерам',
            items: [
                {
                    icon: 'precision_manufacturing',
                    title: 'Автономные станции проката с интерактивным экраном',
                    ul: [
                        '6 слотов для PowerBank'
                    ]
                },{
                    icon: 'battery_charging_full',
                    title: 'Станция PowerBank',
                    ul: [
                        'Каждая станция в соответствии с размером комплектуется аккумуляторами емкостью 5000 mAh'
                    ]
                },{
                    icon: 'auto_awesome',
                    title: 'Автономный сервиc',
                    ul: [
                        'Рекомендации по составлению схемы распространения станций',
                        'Сопровождение при запуске',
                        'Экстренное реагирование на запросы',
                        'Информационная система контроля',
                        'Гарантированная техническая поддержка',
                    ]
                },{
                    icon: 'cloud_done',
                    title: 'toGO cloud',
                    ul: [
                        'Веб-сайт',
                        'Мобильное приложение',
                        'Автономный сервис, не требующий найма сотрудников',
                    ]
                },{
                    icon: 'support_agent',
                    title: 'Поддержка',
                    ul: [
                        'Круглосуточный call-центр',
                        'Маркетинговая поддержка',
                        'Удобные в использовании веб-сервис и мобильное приложение',
                        'Ведите собственные аккаунты в социальных сетях, следуя брендбуку'
                    ]
                },
            ]
        },
        features: {
            title: 'Преимущества сети toGO',
            items: [
                {
                    icon: 'room',
                    title: 'Местоположение станций',
                    p: 'Мы даем рекомендации по составлению схемы расположения станций с учетом трафика общественных мест, рейтинга конкретных заведений и ваших предпочтений. Осуществляем техническую поддержку и сопровождаем на всех этапах запуска. Вы также получаете обучение от нашей компании по работе со станциями и веб-приложением, готовый BrandBook и спрос средизаведений на размещение станций, поэтому что для них это гарантирует поток посетителей, желающих взять PowerBank'
                },{
                    icon: 'psychology',
                    title: 'Технологии',
                    p: 'toGO имеет собственное производство оборудования, соответствующее международным стандартам, которое также в обязательном порядке проходит сертификацию Apple и MCI, что гарантирует качественный и безопасный продукт. К системе toGO легко подключиться – процесс настройки занимает не более 10 минут. Настроенный сервис включает в себя мобильные приложения, веб-сайт и call-центр, что в совоокупности сильно облегчает сотрудничество с нами. Система позволяет проходить регистрацию для аренды PowerBank, принимать платежи, предоставлять кассовые чеки, отслеживать взятые устройства в режиме online и настраивать тарифы аренды.'
                },{
                    icon: 'shop',
                    title: 'Размер станций и уровень дохода',
                    p: 'toGO занимается производством станций разных размеров: малые, средние и большие, каждая из которых оснащена интерактивным экраном, позволяющим размещать рекламу и сотрудничать не только с пользователями, но и с коммерческими компаниями. Средние и большие станции созданы для установки в местах с большим скоплением людей, что позволяет увеличить рекламный охват. Количество слотов под PowerBank зависит от размера станции, чем их больше, тем выше число взятых в аренду аккумуляторов, а вместе с тем и и ваша прибыль. Компания toGO оснащает станции необходимым количеством PowerBank абсолютно бесплатно, что минимизирует тревожность партнеров в случае утраты или поломки аккумулятора'
                },
            ]
        },
        howitworks: {
            img: pb4,
            title: 'Как работает бизнес',
            p1: 'Сервис выстроен абсолютно автономно, освобождая вас от найма управляющего и обслуживающего персонала. После запуска станция будет полностью готова к работе, ее местоположение отразиться на карте, и любой пользователь сможет увидеть активную точку через приложение. Так вы станете частью сети toGO в вашем городе',
            p2: 'В дальнейшем любой желающий сможет арендовать PowerBank. С каждой оплаты за аренду партнер получает доход, а пользователь информацию об оплате и чек',
            p3: 'Сервис toGO автоматически взаимодействует с клиентом, вам остается лишь при необходимости наблюдать за информационной системой, контролирующей операции и перемещение PowerBank'
        },
        powerbank: {
            img: pb5,
            title: 'PowerBank',
            p1: 'Каждая станция комплектуется необходимым количеством аккумуляторов в соответствии с размером станции. За счет встроенного пинка, PowerBank заряжается лишь тогда, когда находится в слоте оригинальной станции toGO',
            p2: 'Аккумуляторы соответствуют международным стандартам качества и безопасности. Их удобно использовать за счет универсального размера и легкости',
            p3: 'PowerBank имеет два провода, что позволяет заряжать мобильные устройства на платформе iOS и Android'
        },
        mobileApp: {
            img: pb2,
            title: 'Удобное мобильное приложение',
            items: [
                {
                    num: 1,
                    p: 'Регистрация занимает меньше минуты'
                },{
                    num: 2,
                    p: 'Аренда PowerBank без лишних услилий'
                },{
                    num: 3,
                    p: 'Поиск и выстраивание маршрута до ближайшей станции'
                },{
                    num: 4,
                    p: 'Контроль времени пользования аккумулятором'
                },{
                    num: 5,
                    p: 'Информация о количестве свободных слотов в станциях для возврата PowerBank'
                },{
                    num: 6,
                    p: 'Бонусная накопительная система для пользователей'
                },{
                    num: 7,
                    p: 'Связь со службой поддержки'
                }
            ]
        },
        infosystem: {
            title: 'Информационная система toGO sharing',
            subtitle: 'Обеспечение контроля в режиме онлайн над доходами, транзакциями, временем проката, статистикой использования и отчетностью',
        },
        support: {
            title: 'Колл-центр и маркетинговая поддержка',
            items: [
                {
                    icon: '',
                    p: 'Доступный 24/7 колл-центр и поддержка пользователей в социальных сетях'
                },
                {
                    icon: '',
                    p: 'Каждый партнер может вести свои собственный аккаунты в социальных сетях, испльзуя банк маркетинговых материалов'
                },
            ]
        },
        launch : {
            title: 'Этапы запуска toGO за 20 дней',
            items: [
                {
                    num: 1,
                    h: 'Заключение договора',
                    p: 'Оплата и присоединение к лицензионному соглашению'
                },{
                    num: 2,
                    h: 'Рекомендация по местам установки',
                    p: 'Заключение договорных отношений с заведением'
                },{
                    num: 3,
                    h: 'Установка оборудования',
                    p: 'Обеспечение доступа в личный кабинет партнера'
                },{
                    num: 4,
                    h: 'Начало работы сети',
                    p: ''
                },
            ]
        }
    },
    en: {
        fair: {
            title: "Франшиза toGO заряд",
            subtitle: "Шеринг портативных зарядных устройств power bank",
        },
        contactUs: {
            title: "Запусти бизнес в своём городе с окупаемостью 6 месяцев, который не требует  полного участия инвестора  - все автоматизировано!",
            btn: 'Обратная связь',
            links: [
                {
                    name: 'Telegram',
                    url: 'https://t.me/togokicksharing',
                    img: telegram,
                },
                {
                    name: 'WhatsApp',
                    url: 'https://api.whatsapp.com/send/?phone=79775388101',
                    img: wa,
                },
            ],
            reverceCall: {
                name: 'Обратная связь',
                img: mail,
            }
        },
        partnersProgram: {
            img: pb1,
            title: 'Партнерская программа toGO заряд',
            p1: 'Стремительно набирающий обороты PowerBank sharing уже с начала своего появления имеет огромный спрос. Современный пользователь ценит мобильность, он всегда в движении и предпочитает заряжать телефон в пути, а не терять время стоя у розетки. К слову, о потери времени, мы создали доступный и понятный веб-сервис для легкой и быстрой регистрации',
            p2: 'Общество давно привыкло платить за удобство и качество жизни, поэтому мы так смело выходим на рынок с нашим решением, привлекаем партнеров и предлагаем зарабатывать вместе',

        },
        analyze: {
            title: 'Анализ рынка шеринга портативных аккумуляторов',
            subtitle: 'Анализ показал, что 10.2% опрошенных хотят воспользоваться и готовы платить за сервис, предоставляемый toGO',
            items: [
                {
                    quontity: '68%',
                    p: 'сталкиваются с проблемой нехватки аккумулятора ежедневно'
                },{
                    quontity: '21,8%',
                    p: 'готовы воспользоваться сервисом и считают его потенциально удобным'
                },{
                    quontity: '10,2%',
                    p: 'готовы платить указанную сумму за пользование сервисом'
                },
            ]
        },
        statistic: {
            title: 'Статистическая справка',
            p1: 'Эксперты McKinsey прогнозируют, что к 2025 году половина мировой экономики будет задействована в совместных моделях потребления.',
            p2: 'Потенциал роста объёма рынка шеринговой экономики, по оценке PwC, значительно выше текущего: отрасль способна вырасти до $335 млрд к 2025 году.',
            items: [
                {
                    quontity: '+85%',
                    p: 'Аренда вещей'
                },{
                    quontity: '-48%',
                    p: 'Шеринг жилья'
                },{
                    quontity: '+48%',
                    p: 'C2C продажи'
                },{
                    quontity: '-36%',
                    p: 'Карпулинг'
                },{
                    quontity: '+35%',
                    p: 'Индивидуальная собственность'
                },{
                    quontity: '-28%',
                    p: 'Офисшеринг'
                },

            ]
        },
        finance: {
            title: 'Финансовая обоснованность',
            items: [
                {
                    icon: 'tune',
                    title: 'Тарифы',
                    ul: [
                        'Час — 49 руб', 'Сутки — 99 руб', 'Средний чек — 74 руб', 'Среднее количество арендованных power bank на одной станции в день — 4'
                    ]
                },{
                    icon: 'payments',
                    title: 'Выручка',
                    ul: [
                        'С одной станции — 296 руб/день', 'С одной станции — 8 800 руб/мес', 'От всей сети — 182 600 руб'
                    ]
                },{
                    icon: 'shopping_bag',
                    title: 'Стоимость',
                    ul: [
                        'Одна станция на 6 слотов — 27 000 руб'
                    ]
                },
            ]
        },
        solution: {
            title: 'Что мы предлагаем партнерам',
            items: [
                {
                    icon: 'precision_manufacturing',
                    title: 'Автономные станции проката с интерактивным экраном',
                    ul: [
                        '6 слотов для PowerBank'
                    ]
                },{
                    icon: 'battery_charging_full',
                    title: 'Станция PowerBank',
                    ul: [
                        'Каждая станция в соответствии с размером комплектуется аккумуляторами емкостью 5000 mAh'
                    ]
                },{
                    icon: 'auto_awesome',
                    title: 'Автономный сервиc',
                    ul: [
                        'Рекомендации по составлению схемы распространения станций',
                        'Сопровождение при запуске',
                        'Экстренное реагирование на запросы',
                        'Информационная система контроля',
                        'Гарантированная техническая поддержка',
                    ]
                },{
                    icon: 'cloud_done',
                    title: 'toGO cloud',
                    ul: [
                        'Веб-сайт',
                        'Мобильное приложение',
                        'Автономный сервис, не требующий найма сотрудников',
                    ]
                },{
                    icon: 'support_agent',
                    title: 'Поддержка',
                    ul: [
                        'Круглосуточный call-центр',
                        'Маркетинговая поддержка',
                        'Удобные в использовании веб-сервис и мобильное приложение',
                        'Ведите собственные аккаунты в социальных сетях, следуя брендбуку'
                    ]
                },
            ]
        },
        features: {
            title: 'Преимущества сети toGO',
            items: [
                {
                    icon: 'room',
                    title: 'Местоположение станций',
                    p: 'Мы даем рекомендации по составлению схемы расположения станций с учетом трафика общественных мест, рейтинга конкретных заведений и ваших предпочтений. Осуществляем техническую поддержку и сопровождаем на всех этапах запуска. Вы также получаете обучение от нашей компании по работе со станциями и веб-приложением, готовый BrandBook и спрос средизаведений на размещение станций, поэтому что для них это гарантирует поток посетителей, желающих взять PowerBank'
                },{
                    icon: 'psychology',
                    title: 'Технологии',
                    p: 'toGO имеет собственное производство оборудования, соответствующее международным стандартам, которое также в обязательном порядке проходит сертификацию Apple и MCI, что гарантирует качественный и безопасный продукт. К системе toGO легко подключиться – процесс настройки занимает не более 10 минут. Настроенный сервис включает в себя мобильные приложения, веб-сайт и call-центр, что в совоокупности сильно облегчает сотрудничество с нами. Система позволяет проходить регистрацию для аренды PowerBank, принимать платежи, предоставлять кассовые чеки, отслеживать взятые устройства в режиме online и настраивать тарифы аренды.'
                },{
                    icon: 'shop',
                    title: 'Размер станций и уровень дохода',
                    p: 'toGO занимается производством станций разных размеров: малые, средние и большие, каждая из которых оснащена интерактивным экраном, позволяющим размещать рекламу и сотрудничать не только с пользователями, но и с коммерческими компаниями. Средние и большие станции созданы для установки в местах с большим скоплением людей, что позволяет увеличить рекламный охват. Количество слотов под PowerBank зависит от размера станции, чем их больше, тем выше число взятых в аренду аккумуляторов, а вместе с тем и и ваша прибыль. Компания toGO оснащает станции необходимым количеством PowerBank абсолютно бесплатно, что минимизирует тревожность партнеров в случае утраты или поломки аккумулятора'
                },
            ]
        },
        howitworks: {
            img: pb4,
            title: 'Как работает бизнес',
            p1: 'Сервис выстроен абсолютно автономно, освобождая вас от найма управляющего и обслуживающего персонала. После запуска станция будет полностью готова к работе, ее местоположение отразиться на карте, и любой пользователь сможет увидеть активную точку через приложение. Так вы станете частью сети toGO в вашем городе',
            p2: 'В дальнейшем любой желающий сможет арендовать PowerBank. С каждой оплаты за аренду партнер получает доход, а пользователь информацию об оплате и чек',
            p3: 'Сервис toGO автоматически взаимодействует с клиентом, вам остается лишь при необходимости наблюдать за информационной системой, контролирующей операции и перемещение PowerBank'
        },
        powerbank: {
            img: pb5,
            title: 'PowerBank',
            p1: 'Каждая станция комплектуется необходимым количеством аккумуляторов в соответствии с размером станции. За счет встроенного пинка, PowerBank заряжается лишь тогда, когда находится в слоте оригинальной станции toGO',
            p2: 'Аккумуляторы соответствуют международным стандартам качества и безопасности. Их удобно использовать за счет универсального размера и легкости',
            p3: 'PowerBank имеет два провода, что позволяет заряжать мобильные устройства на платформе iOS и Android'
        },
        mobileApp: {
            img: pb2,
            title: 'Удобное мобильное приложение',
            items: [
                {
                    num: 1,
                    p: 'Регистрация занимает меньше минуты'
                },{
                    num: 2,
                    p: 'Аренда PowerBank без лишних услилий'
                },{
                    num: 3,
                    p: 'Поиск и выстраивание маршрута до ближайшей станции'
                },{
                    num: 4,
                    p: 'Контроль времени пользования аккумулятором'
                },{
                    num: 5,
                    p: 'Информация о количестве свободных слотов в станциях для возврата PowerBank'
                },{
                    num: 6,
                    p: 'Бонусная накопительная система для пользователей'
                },{
                    num: 7,
                    p: 'Связь со службой поддержки'
                }
            ]
        },
        infosystem: {
            title: 'Информационная система toGO sharing',
            subtitle: 'Обеспечение контроля в режиме онлайн над доходами, транзакциями, временем проката, статистикой использования и отчетностью',
        },
        support: {
            title: 'Колл-центр и маркетинговая поддержка',
            items: [
                {
                    icon: '',
                    p: 'Доступный 24/7 колл-центр и поддержка пользователей в социальных сетях'
                },
                {
                    icon: '',
                    p: 'Каждый партнер может вести свои собственный аккаунты в социальных сетях, испльзуя банк маркетинговых материалов'
                },
            ]
        },
        launch : {
            title: 'Этапы запуска toGO за 20 дней',
            items: [
                {
                    num: 1,
                    h: 'Заключение договора',
                    p: 'Оплата и присоединение к лицензионному соглашению'
                },{
                    num: 2,
                    h: 'Рекомендация по местам установки',
                    p: 'Заключение договорных отношений с заведением'
                },{
                    num: 3,
                    h: 'Установка оборудования',
                    p: 'Обеспечение доступа в личный кабинет партнера'
                },{
                    num: 4,
                    h: 'Начало работы сети',
                    p: ''
                },
            ]
        }
    }
}

const powerBankReducer = (state = initialState, action) => {
    return state
}

export default powerBankReducer