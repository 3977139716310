import logoGreen from './../resources/client/logo-green.svg'
import logoViolet from './../resources/client/logo-violet.svg'
import logoDark from './../resources/client/logo-dark.svg'
import sinceImg from './../resources/client/sinceImg.svg'
import scooterSm from './../resources/client/scooter-sm.png'
//social
import waLogo from './../resources/wa-logo.svg'
import facebook from './../resources/social/fb-icon.svg'
import instagram from './../resources/social/insta-icon.svg'
import vk from './../resources/social/vk-icon.svg'
import youtube from './../resources/social/youtube-icon.svg'
import tg from './../resources/social/tg-logo.svg'
//cities
import krasnoznamensk from './../resources/client/cities/krasnoznamensk.png'
import bishkek from './../resources/client/cities/bishkek.png'
import derbent from './../resources/client/cities/derbent.png'
import gatchina from './../resources/client/cities/gatchina.png'
import gelendjik from './../resources/client/cities/gelendjik.png'
import grozny from './../resources/client/cities/grozny.png'
import orenburg from './../resources/client/cities/orenburg.png'
import rostov from './../resources/client/cities/rostov.png'
import batumi from './../resources/client/cities/batumi.png'
import khabarovsk from './../resources/client/cities/khabarovsk.png'
import moscow from './../resources/client/cities/moscow.png'
import astrahan from './../resources/client/cities/astrahan.png'
import bransk from './../resources/client/cities/bransk.png'
import sochi from './../resources/client/cities/sochi.png'
import komsomolsk from './../resources/client/cities/komsomolsk.png'
import siktivkar from './../resources/client/cities/siktivkar.png'



let initialState = {
    ru: {
        hero: {
            logoGreen: logoGreen,
            logoViolet: logoViolet,
            logoDark: logoDark,
            title: 'Кикшеринг toGO',
            subtitle: 'Установите приложение и сокращайте расстояния!',
            sinceImg: sinceImg
        },
        instruction: {
            title: 'Как использовать',
            items: [
                {
                    emoji: '🐣',
                    title: 'Установите приложение toGO'
                }, {
                    emoji: '😎',
                    title: 'Зарегистрируйтесь или войдите в аккаунт'
                }, {
                    emoji: '📍',
                    title: 'Выберите самокат на карте'
                }, {
                    emoji: '🛴',
                    title: 'Сканируйте QR код и наслаждайтесь поездкой'
                }
            ]
        },
        support: {
            title: 'Техподдержка',
            subtitle: 'Возникли неполадки или вопросы при использовании сервиса? Напишите нам в WhatsApp',
            waIcon: waLogo,
            phone: '+7 (938) 158-77-55',
            btnText: 'Написать в поддержку',
            btnUrl: 'https://wa.me/79381587755'
        },
        faq: 'Частые вопросы',
        social: {
            title: 'Следите за toGO в социальных сетях',
            items: [
                {
                    icon: instagram,
                    link: 'https://www.instagram.com/samokat_sharing/',
                    alt: 'instagram'
                }, {
                    icon: facebook,
                    link: 'https://www.facebook.com/samokatsharing/',
                    alt: 'facebook'
                }, {
                    icon: vk,
                    link: 'https://vk.com/samokat_sharing',
                    alt: 'vk'
                }, {
                    icon: youtube,
                    link: 'https://www.youtube.com/channel/UC1hCLX08gLhWJpNupaDj7eA',
                    alt: 'youtube'
                }, {
                    icon: tg,
                    link: 'https://t.me/+TzOg-q7xKGXOpixL',
                    alt: 'telegram'
                }
            ]
        },
        presence: [
            {
                img: moscow,
                city: 'Москва',
                url: 'https://www.instagram.com/samokat_sharing/'
            },{
                img: sochi,
                city: 'Сочи',
                url: 'https://www.instagram.com/togo_sochi/'
            },{
                img: astrahan,
                city: 'Астрахань',
                url: 'https://www.instagram.com/samokat_sharing/'
            },{
                img: bransk,
                city: 'Брянск',
                url: 'https://www.instagram.com/samokat_sharing/'
            },{
                img: krasnoznamensk,
                city: 'Краснознаменск',
                url: 'https://www.instagram.com/togo_krasnoznamensk/'
            }, {
                img: orenburg,
                city: 'Оренбург',
                url: 'https://www.instagram.com/togo_orenburg/'
            }, {
                img: rostov,
                city: 'Ростов-на-Дону',
                url: 'https://www.instagram.com/togo_rnd/'
            }, {
                img: grozny,
                city: 'Грозный',
                url: 'https://www.instagram.com/togo_grozny/'
            }, {
                img: gatchina,
                city: 'Гатчина',
                url: 'https://www.instagram.com/togo_gatchina/'
            }, {
                img: derbent,
                city: 'Дербент',
                url: 'https://www.instagram.com/togo_derbent/'
            }, {
                img: gelendjik,
                city: 'Геленджик',
                url: 'https://www.instagram.com/togo_gel/'
            }, {
                img: bishkek,
                city: 'Бишкек',
                url: 'https://www.instagram.com/togolife.kg/'
            }, {
                img: batumi,
                city: 'Батуми, Тбилиси',
                url: 'https://www.instagram.com/togogeo/'
            }, {
                img: khabarovsk,
                city: 'Хабаровск',
                url: 'https://www.instagram.com/togo_khv/'
            }, {
                img: komsomolsk,
                city: 'Комсомольск-на-Амуре',
                url: 'https://www.instagram.com/samokat_sharing/'
            },{
                img: siktivkar,
                city: 'Сыктывкар',
                url: 'https://www.instagram.com/samokat_sharing/'
            }
        ],
        catch: {
            title: 'Присоедините свой город',
            subtitle: 'Запустите свой собственный кикшеринг вместе с toGO',
            btnText: 'Подробнее о запуске Кикшеринга >',
            img: scooterSm
        }
    },
    en: {
        hero: {
            logoGreen: logoGreen,
            logoViolet: logoViolet,
            logoDark: logoDark,
            title: 'toGO kicksharing',
            subtitle: 'Install the app and simplify the distance!',
            sinceImg: sinceImg
        },
        instruction: {
            title: 'How to use',
            items: [
                {
                    emoji: '🐣',
                    title: 'Install toGO app'
                }, {
                    emoji: '😎',
                    title: 'Register or sign in to your account'
                }, {
                    emoji: '📍',
                    title: 'Select your scooter on the map'
                }, {
                    emoji: '🛴',
                    title: 'Scan QR-code on scooter and enjoy your ride'
                }
            ]
        },
        support: {
            title: 'Support',
            subtitle: 'If you have a malfunctions or questions while use service, will you text to us on WhatsApp',
            waIcon: waLogo,
            phone: '+7 (938) 158-77-55',
            btnText: 'Contact our support',
            btnUrl: 'https://wa.me/79381587755'
        },
        faq: 'FAQ',
        social: {
            title: 'Follow us',
            items: [
                {
                    icon: instagram,
                    link: 'https://www.instagram.com/samokat_sharing/',
                    alt: 'instagram'
                }, {
                    icon: facebook,
                    link: 'https://www.facebook.com/samokatsharing/',
                    alt: 'facebook'
                }, {
                    icon: vk,
                    link: 'https://vk.com/samokat_sharing',
                    alt: 'vk'
                }, {
                    icon: youtube,
                    link: 'https://www.youtube.com/channel/UC1hCLX08gLhWJpNupaDj7eA',
                    alt: 'youtube'
                }, {
                    icon: tg,
                    link: 'https://t.me/joinchat/TzOg-qdcd-vSeo2U',
                    alt: 'telegram'
                }
            ]
        },
        presence: [
            {
                img: moscow,
                city: 'Moscow',
                url: 'https://www.instagram.com/samokat_sharing/'
            },{
                img: sochi,
                city: 'Sochi',
                url: 'https://www.instagram.com/togo_sochi/'
            },{
                img: astrahan,
                city: 'Astrakhan',
                url: 'https://www.instagram.com/samokat_sharing/'
            },{
                img: bransk,
                city: 'Bryansk',
                url: 'https://www.instagram.com/samokat_sharing/'
            },{
                img: krasnoznamensk,
                city: 'Krasnoznamensk',
                url: 'https://www.instagram.com/togo_krasnoznamensk/'
            }, {
                img: orenburg,
                city: 'Orenburg',
                url: 'https://www.instagram.com/togo_orenburg/'
            }, {
                img: rostov,
                city: 'Rostov-on-Don',
                url: 'https://www.instagram.com/togo_rnd/'
            }, {
                img: grozny,
                city: 'Grozny',
                url: 'https://www.instagram.com/togo_grozny/'
            }, {
                img: gatchina,
                city: 'Gatchina',
                url: 'https://www.instagram.com/togo_gatchina/'
            }, {
                img: derbent,
                city: 'Derbent',
                url: 'https://www.instagram.com/togo_derbent/'
            }, {
                img: gelendjik,
                city: 'Gelendzhik',
                url: 'https://www.instagram.com/togo_gel/'
            }, {
                img: bishkek,
                city: 'Bishkek',
                url: 'https://www.instagram.com/togolife.kg/'
            }, {
                img: batumi,
                city: 'Batumi, Tbilisi',
                url: 'https://www.instagram.com/togogeo/'
            }, {
                img: khabarovsk,
                city: 'Khabarovsk',
                url: 'https://www.instagram.com/togo_khv/'
            },{
                img: komsomolsk,
                city: 'Komsomolsk-on-Amur',
                url: 'https://www.instagram.com/samokat_sharing/'
            },{
                img: siktivkar,
                city: 'Syktyvkar',
                url: 'https://www.instagram.com/samokat_sharing/'
            }
        ],
        catch: {
            title: 'Connect your city',
            subtitle: 'Launch your own kicksharing with toGO',
            btnText: 'More about >',
            img: scooterSm
        }
    }
}

const ClientPageReducer = (state = initialState, action) => {
    return state
}

export default ClientPageReducer