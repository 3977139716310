const SET_VISIBLE = 'SET-VISIBLE'

let initialState = {
    ru: {
        menu: [
            {
                url: '/franchise',
                text: 'Франшиза Кикшеринг'
            },{
                url: '/charge',
                text: 'Франшиза PowerBank'
            },{
                url: '/development',
                text: 'Разработка'
            },{
                url: '/press',
                text: 'СМИ о нас'
            },
            {
                url: '/accreditation',
                text: 'Аккредитации'
            },{
                url: '/quick-faq',
                text: 'FAQ'
            }
        ],
    },
    en: {
        menu: [
            {
                url: '/franchise',
                text: 'toGO franchise'
            },
            {
                url: '/quick-faq',
                text: 'FAQ'
            }
        ],
    },
    visible: false
}

const mainMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VISIBLE:
            return {
                ...state,
                visible: action.visible
            }
        default:
            return state
    }
}

export const setVisible = (visible) => ({
    type: SET_VISIBLE,
    visible: visible
})

export default mainMenuReducer