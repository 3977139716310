import React from 'react'
import { animateScroll as scroll } from "react-scroll";
import fairill from './../../resources/certificates/case.png'

const Press = (props) => {
    scroll.scrollToTop()

    let certItems = props.state.arr.map(m =>
        <a className='tg-press-card' href={m.certificate} target='_blank' rel="noopener noreferrer">
            <div className='tg-press-img'>
                <img src={m.img}/>
            </div>
            <div className='tg-press-description'>
                <h3>{m.title}</h3>
            </div>
        </a>
    )

    return (
        <div>
            <div className='tg-accreditation-fairill'>
                <img src={fairill} alt="case" />
            </div>
            <div className='primary'>
                <div className='grid-container'>
                    {certItems}
                </div>
            </div>
        </div>
    )
}

export default Press