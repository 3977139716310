import React from 'react'

import logo from './../../resources/logo.svg'
import facebook from './../../resources/social/fb-icon.svg'
import insta from './../../resources/social/insta-icon.svg'
import vk from './../../resources/social/vk-icon.svg'
import youtube from './../../resources/social/youtube-icon.svg'
import tg from './../../resources/social/tg-logo.svg'
import certificate from './../../resources/certificates/CertificateTeltonika.png'

import { NavLink } from 'react-router-dom'

const Footer = (props) => {
    return (
        <div className='footerContainer'>
            <div className='topLevel'>
                <img src={logo} alt="toGO" />
                <div className='social'>
                    <a href="https://www.instagram.com/samokat_sharing/" target='_blank' rel="noopener noreferrer">
                        <img src={insta} alt="instagram" />
                    </a>
                    <a href="https://www.facebook.com/samokatsharing/" target='_blank' rel="noopener noreferrer">
                        <img src={facebook} alt="facebook" />
                    </a>
                    <a href="https://vk.com/samokat_sharing" target='_blank' rel="noopener noreferrer">
                        <img src={vk} alt="VK" />
                    </a>
                    <a href="https://www.youtube.com/channel/UC1hCLX08gLhWJpNupaDj7eA" target='_blank' rel="noopener noreferrer">
                        <img src={youtube} alt="YouTube" />
                    </a>
                    <a href="https://t.me/+TzOg-q7xKGXOpixL" target='_blank' rel="noopener noreferrer">
                        <img src={tg} alt="Telegram"/>
                    </a>
                </div>
            </div>
            <div className='secondLevel'>
                <p>{props.state.sign}</p>
                <span>©2018—2022 |</span>
                <a href="mailto:info@to-go.life">
                    <span>info@to-go.life</span>
                </a>
            </div>
            <div className='policy'>
                <NavLink to='/privacy-policy'><span className="material-icons">launch</span>{props.state.links.privacy}</NavLink>
                <NavLink to='/joining-togo'><span className="material-icons">launch</span>{props.state.links.joining}</NavLink>
                <NavLink to='/quick-faq'><span className="material-icons">help_outline</span>{props.state.links.faq}</NavLink>
                <NavLink to='/certificates'><span className="material-icons">verified</span>{props.state.links.certificate}</NavLink>
            </div>
        </div>
    )
}

export default Footer